export default (state, action) => {
  switch (action.type) {
    case "SET_CONFERENCES":
      return {
        ...state,
        conferences: action.payload,
      };
    case "SET_CONFERENCE":
      return {
        ...state,
        currentConference: action.payload,
      };
    case "SENDING_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "REQUEST_FINISHED":
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
