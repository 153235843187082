import React from "react";
import Sidebar from "../../components/common/Sidebar";

export default function Layout(props) {
  return (
    <>
      {/* headerSection*/}
      {/*Main section of the dashboard*/}
      <section>
        <div className='container'>
          <div className='row'>
            {/****** 2nd-column of Body section******/}
            <div className='col col-md-12 pl-0'>{props.children}</div>
            {/* <div className='col col-md-9 pl-0'>{props.children}</div> */}
            {/****left-side bar*/}
            {/* <div className='col-md-2 pr-0'>
              <Sidebar />
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}
