import React from "react";
import EventListing from "./EventListing";
import DashboardLayout from "../layout/DashboardLayout";

const EventPage = () => {
  return (
    <>
      <DashboardLayout page_name='Events'>
        <EventListing />
      </DashboardLayout>
    </>
  );
};

export default EventPage;

// import React from "react";
// import Header from "../DashboardHeader";
// import SubHeader from "../SubHeader";

// import Sidebar from "../Sidebar";
// import EventListing from "./EventListing";

// export default function DashboardEventsLayout() {
//   return (
//     <>
//       <Header />
//       <SubHeader name='Events' />

//       {/*Main section of the dashboard*/}
//       <section id='dashboard-main'>
//         <div className='container'>
//           <div className='row'>
//             {/*start left-side bar*/}
//             <div className='col-md-3 pl-0'>
//               <Sidebar />
//             </div>
//             {/*End left-side bar*/}

//             {/****** Start the 2nd-column******/}
//             <div className='col col-md-9 pr-0'>
//               <EventListing name='Events' />
//             </div>
//             {/****** End the 2nd-column******/}
//           </div>
//         </div>
//       </section>
//     </>
//   );
// }
