import React from "react";
// import "./course-style.css";

import Sidebar from "../../components/common/Sidebar";
import Breadcrumb from "../../components/common/Breadcrumb";
import CardUIProject from "../../components/card/CardUIProject";

import Layout from "../layout/Layout";
// import Section from "../../components/common/Section";
//import SearchForm from "./SearchForm";

import CardUI from "../../components/common/CourseCardUI";

// import img1 from "../../assets/images/kt_04_small.png";

//import SearchForm from "./SearchForm";

export default function LayoutProjectsPage() {
  // let headerSection = [<Breadcrumb name='Scale Up-Projects' />];
  // let sidebar = [<Sidebar />];
  // let bodySections = [
  //   // <Section
  //   //   title='CLS Courses'
  //   //   body={"Sample description of the projects..."}
  //   // />,
  // ];

  return (
    <>
      {/* {headerSection} */}
      <Breadcrumb name='Long-Term Partnerships Projects' />
      <Layout>
        {/* {bodySections} */}
        <div>
          <div className='card text-center shadow'>
            <div className='card-body text-dark'>
              <h5 className='card-title'>Overview</h5>
              {/* /************************ */}
              <p className='card-text text-secondary text-justify'>
                {/* <strong>Description : </strong> */}
                The projects described here all started as course assignments
                that were scaled up. In addition to working toward a conference
                presentation, project teams pursued: funding applications to
                conduct research or engage in knowledge mobilization;
                opportunities to apply knowledge in the real world; and
                peer-reviewed publications. All papers presented in Exchange
                2020 were long-term partnerships; teams presented their work in
                progress.
              </p>
            </div>
          </div>
        </div>
        ,{/* Projects */}
        <CardUIProject
          imgsrc={""}
          title='People with Lived Experience in Roles of Leadership within Community Organizations'
          link_to='/courses'
          Investigators='Sarah Tremblett, Emily Wadden, Lilian Klein, Christina Traverse, Melendy Muise, & Stephen Ellenbogen'
          title_p1='Summary of project:'
          description_p1='In this scoping review and expert consultation, we employed an iterative approach that incorporated the knowledge and advice of professional peer support workers with lived experiences (PSWLE) who reflected on how key findings relate to their work experiences. Themes extracted highlighted that (1) PSWLE are engaged in a myriad of interconnected roles, (2) their work benefits service users, service providers, social service organizations and the community, and (3) PSWLE face a number of challenges and barriers, such as lack of training opportunities, transitioning difficulties, and employment insecurity. Recommendations and social work implications are discussed.'
          title_p2='Project origin and development:'
          description_p2='IThis initiative is an offshoot of an earlier partnership project (see Tremblett and colleague’s paper from Exchange 2018). Muise participated as community partner, offering insight from her experiences as PSWLE. This sparked interest in exploring PSWLE as a research topic. The scoping review and consultation was conducted independently by four recent Bachelor of Social Work graduates Tremblett, Klein, Traverse, and Wadden, with Ellenbogen as research mentor. Muise acted as community consultant on the review. Two of her colleagues also participated in the consultation. All three consultants work for community-based organizations and self-identify as having lived experiences of sexual exploitation. Ellenbogen and Muise joined the research team after the production of an initial draft. The team is nearing completion of a manuscript to be submitted to a peer-reviewed journal. An early draft of the paper was presented at Exchange 2020 (see Tremblett and colleague’s video).'
        />
        ,
        <CardUIProject
          imgsrc={""}
          title='Exploring the Accessibility of Oncology Camps for Children and Families Affected by Childhood Cancer in Newfoundland and Labrador'
          link_to='/courses'
          Investigators='Lisa Blundell, Sulaimon Giwa, Sarah-Kay Walker, Stephen Ellenbogen, Keeley Hatfield'
          title_p1='Summary of project:'
          description_p1='Camp Delight is a residential camp for children between the ages of 7 and 17 who have been impacted by childhood cancer. This includes children who are currently undergoing cancer treatment or have completed treatment, their siblings, and bereaved siblings. While the number of camp attendees has risen over the years since its initiation in 1987, factors that increase access to Camp Delight or hinder families from attending are not well understood. Also, campers’ experiences are not well documented.  Research questions: What are the factors that lead to children attending Camp Delight and what are the barriers that prevent others from doing so? What influences the choice of campers and families in deciding whether to return to Camp Delight in future years? What can be improved that would increase the accessibility of Camp Delight for eligible families? The objectives are: Methods: This is a participatory action research project that has begun with and will continue to involve members of Candlelighters NL. We conducted semi-structured interviews with 11 families of children who have attended Camp and had them transcribed. Each transcript was coded and the data was examined using Braun & Clark’s analytical framework for conducting thematic analysis. will be implemented to identify themes present in the data. '
          title_p2='Project origin and development:'
          description_p2='This research project, funded through Memorial University Seed, Bridge and Multidisciplinary Fund, was informed by a scale-up project presented at Exchange 2020: Benefits of Oncology camps, by Jessica Courage, Jared Davies, Lisa Blundell and Guy Courage.  '
        />
        ,
        <CardUIProject
          imgsrc={""}
          title='Exploring cannabis harm reduction strategies for women through community-based participatory research'
          link_to='/courses'
          Investigators='Krista Marsh, Julia Janes, Stephen Ellenbogen, Sulaimon Giwa, Heather Bartlett, Jill Peckford, Emily Wadden, and Sabrina Baker'
          title_p1='Summary of project:'
          description_p1='In a review of cannabis and harm reduction literature, we found few attempts to reconcile divergent perspectives. Most public documents on cannabis can be divided into two camps: health authorities reports that focus on risks and prevention; and vendor-supported pamphlets/guides that stress medicinal and other benefits and provide information on safe consumption. Also, we found that few harm reduction documents were co-created for and by female-identifying cannabis users. Research questions: What are female-identifying cannabis users’ perspectives on: realities associated with cannabis use; strengths and gaps of existing harm reduction (HR) tools (e.g., public documents, guides, technical reports); and how cannabis HR tools might be improved? What are the perspectives of community workers who support this population on these topics?  Methods: A steering committee selected documents and procedures to guide a focus group discussion on harm reduction. Participants will review selected existing harm reduction tools and research, and use their lived experiences to determine their merit for harm reduction. Findings will inform a resource development document. The project just received ethics approval.  
'
          title_p2='Project origin and development:'
          description_p2='This research project is funded through Canadian Research Initiative in Substance Misuse (CRISM) Québec-Atlantic node’s Research Development Program (RDP) Fund. The project was informed by a scale-up project presented at Exchange 2020: Exploring Harm Reduction Strategies for Cannabis, by Jenny Brace, Claire Riggs, and Heather Bartlett. '
        />
        ,
        <CardUIProject
          imgsrc={""}
          title='Informing Social Work Regulation in the North'
          link_to='/courses'
          Investigators='Bianca Martin and Beckie Huston, Jordan Aslett, and the Association of Social Workers in Northern Canada (community partner)'
          title_p1='Summary of project:'
          description_p1='we will update soon...'
        />
        ,
      </Layout>
    </>
  );
}
