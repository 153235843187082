import React from "react";

import getData from "./data_events";
import CardUI from "../../components/card/CardUI";

// import Event from "./Event";
// import EventListingTitle from "./EventListingTitle";
//import Modal from "../common/Modal";
//import BodySection from "./BodySection";

export default function EventsListing() {
  let events = getData();

  return (
    <>
      <section>
        <CardUI
          title={"Events Listing"}
          body={""}
          showBiggerTitle={true}
          key={"key-conf-1"}
        >
          {/**********Event Listing*********** */}
          <table className='table table-striped table-hover mt-0'>
            <thead>
              <tr>
                <th scope='col'>Date</th>
                <th scope='col'> Time</th>
                <th scope='col'> Event</th>
                <th scope='col'> Location </th>
              </tr>
            </thead>
            <tbody>
              {events.map((data, index) => {
                return (
                  <tr key={index}>
                    <th scope='row'>{data.date}</th>
                    <td>{data.time}</td>
                    <td>
                      <a
                        href={data.event_link}
                        rel='noopener noreferrer'
                        target='_blank'
                      >
                        {data.title}
                      </a>
                    </td>
                    <td> {data.location}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {/*---- End event listing -----*/}
          {/**********Event Listing*********** */}
          {/* <br />

            <EventListingTitle />
            {events.map((data, index) => {
              console.log(index);
              return (
                <Event
                  id={data.index}
                  title={data.title}
                  date={data.date}
                  time={data.time}
                  location={data.location}
                  description={data.description}
                  presenter={data.presenter}
                  event_link={data.event_link}
                />
              );
            })} */}

          {/*---- End event listing -----*/}
        </CardUI>
      </section>

      {/* <BodySection /> */}
      {/* <div className='card-body'>
        <Modal />
        <Modal />
        <Modal />
      </div> */}
    </>
  );
}
