import React from "react";
import { NavLink } from "react-router-dom";

export default function RepositoryOverviewUI(props) {
  const sampleText = (
    <p>
      The research summaries found in this repository are conference
      presentations and posters that were created through collaborations between
      graduating Bachelor of Social Work (BSW) students, Community-Based
      Organizations (CBO), and graduate student/faculty mentors. Each
      collaboration is called a
      <NavLink to='/outcomes'> Scale-Up Project</NavLink>
      .
      <br /> <br />
      Conference contributions can be accessed as videos of conference
      presentations, slide presentations, papers, and posters. The videos, slide
      presentations and papers were prepared as part of community service
      learning activities. Posters were created from assignments for other
      undergraduate courses, with guidance from graduate student and faculty
      mentors.
    </p>
  );
  return (
    <>
      <div>
        {/* <div className='card' style={{ boxShadow: " 0px 0px #ffffff" }}> */}
        <div className='card text-center shadow mb-2'>
          {/* /************************ */}
          {props.showBiggerTitle && (
            <div className='card-header conference-bg-color mb-0'>
              <div className='card-title new-card-title'>
                <b>{props.title} </b>
              </div>
            </div>
          )}

          {/* /************************ */}
          <div className='card-body text-dark'>
            {/*---- Start the Text-Style title -----*/}
            {!props.showBiggerTitle && (
              <div>
                <h5 className='card-title'>
                  <b>{props.title} </b>
                </h5>
                <div className='border-top border-primary w-75 mx-auto my-3'></div>
              </div>
            )}
            {/*---- End the Text-Style title -----*/}
            {/*---- Start the overview blocks -----*/}
            {/* <div className='row'> */}
            {/*---- Block-1 -----*/}
            {/* <div className='col-md-4'>
                <div className='well dash-box'>
                  <h2>
                    <span
                      className='fa fa-user fa-lg px-2'
                      aria-hidden='true'
                    ></span>
                    203
                  </h2>
                  <h5>Total Participants </h5>
                </div>
              </div> */}
            {/*---- Block-2 -----*/}
            {/* <div className='col-md-4'>
                <div className='well dash-box'>
                  <h2>
                    <span
                      className='fa fa-street-view fa-lg px-2'
                      aria-hidden='true'
                    ></span>
                    101
                  </h2>
                  <h5>Oral Presenters</h5>
                </div>
              </div> */}
            {/*---- Block-3-----*/}
            {/* <div className='col-md-4'>
                <div className='well dash-box'>
                  <h2>
                    <span
                      className='fa fa-newspaper-o fa-lg px-2'
                      aria-hidden='true'
                    ></span>
                    102
                  </h2>
                  <h5>Poster Presenters</h5>
                </div>
              </div>
            </div> */}
            {/*---- End the blocks -----*/}
            {/* /************************ */}
            {/* <div className='border-top border-primary w-75 mx-auto my-3'></div> */}
            {/* /**************Use one of the p tag ********** */}
            <div className='text-justify px-4'>
              {/* <p className='card-text text-secondary text-justify'> */}
              {/* <p className='card-text text-justify px-4'> */}
              {props.body ? props.body : sampleText}
            </div>
            {/* /************************ */}
            <div className='text-justify card-text'>{props.children}</div>
            {/* /************************ */}
          </div>
        </div>
      </div>
    </>
  );
}
