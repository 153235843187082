import React from "react";
import { Link } from "react-router-dom";

import "./partners-style.css";

import MUN from "../../assets/images/logo/MUN_standard.png";
import MUN1 from "../../assets/images/logo/mun.png";

import HCenter from "../../assets/images/logo/HCenter_logo-vertRGB.jpg";
import HCenter1 from "../../assets/images/logo/harris-center.jpg";

import SSHrc from "../../assets/images/logo/sshrc.png";
import SSHrc1 from "../../assets/images/logo/sshrc-1.png";

import yukon_university from "../../assets/images/logo/yukon_university.png";
import yukon_university1 from "../../assets/images/logo/yukon_university1.png";

export default function Partners() {
  return (
    <>
      <div className='jumbotron py-5 mb-0'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 col-lg-12 col-xl-12 my-auto'>
              <div className='partner-wrap'>
                <h1> Our Partners</h1>
                <hr />
                <div className='partner'>
                  <ul>
                    <li>
                      <Link
                        to={{
                          pathname: "https://www.mun.ca",
                        }}
                        target='_blank'
                      >
                        <img src={MUN} alt='' />
                        <img src={MUN1} alt='' />{" "}
                      </Link>
                    </li>

                    <li>
                      <Link
                        to={{
                          pathname: "https://www.mun.ca/harriscentre/",
                        }}
                        target='_blank'
                      >
                        <img src={HCenter} alt='' />
                        <img src={HCenter1} alt='' />{" "}
                      </Link>
                    </li>

                    <li>
                      <Link
                        to={{
                          pathname: "https://www.yukonu.ca/",
                        }}
                        target='_blank'
                      >
                        <img src={yukon_university} alt='' />
                        <img src={yukon_university1} alt='' />{" "}
                      </Link>
                    </li>
                    {/* 
                    <li>
                      <Link
                        to={{
                          pathname:
                            "https://www.sshrc-crsh.gc.ca/home-accueil-eng.aspx",
                        }}
                        target='_blank'
                      >
                        <img src={SSHrc} alt='' />
                        <img src={SSHrc1} alt='' />{" "}
                      </Link>
                    </li> */}
                    {/* 
                    <li>
                      <Link
                        to={{
                          pathname: "https://www.mun.ca/harriscentre/",
                        }}
                        target='_blank'
                      >
                        <img src={HCenter} alt='' />
                        <img src={HCenter1} alt='' />{" "}
                      </Link>
                    </li> */}
                    {/* 
                    <li>
                      <Link
                        to={{
                          pathname: "https://www.mun.ca",
                        }}
                        target='_blank'
                      >
                        <img src={MUN} alt='' />
                        <img src={MUN1} alt='' />{" "}
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
