import Axios from "axios";
import authHeader from "./authHeader";
import errorhandler from "./errorHandler";

export const login = async (user_credentials) => {
  return await Axios.post("/api/auth/login", user_credentials)
    .then((res) => {
      if (res.data.auth_status) {
        // localStorage.setItem("token", res.data.token);
        localStorage.setItem("user", JSON.stringify(res.data));
        // console.log("Stringify:", JSON.stringify(res.data));
        //console.log("Data.User:", res.data);
      }
      return res;
    })
    .catch((err) => {
      errorhandler(err);
      alert("Submission not successful ! Error: " + err);
    });
};

export const logout = async () => {
  await Axios.post("/api/auth/logout", { headers: authHeader() })
    .then((res) => {
      if (res.data.logout_status) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
      } else {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
      }
    })
    .catch((err) => {
      errorhandler(err);
    });
};

export const getAuthStatus = async () => {
  return await Axios.get("/api/auth/auth-status", { headers: authHeader() });
  //OR
  // return Axios.get("/auth/auth-status", { headers: authHeader() });
};

export const fetchData = async () => {
  return await Axios.get("/api/auth/auth-status");
};

const AuthService = { login, logout, getAuthStatus, fetchData };
export default AuthService;
