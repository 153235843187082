import React from "react";
import ContactInfo from "../contact/ContactInfo";
import Layout from "../layout/Layout";
import CardUI from "../../components/card/CardUI";
// import ContactDetail from "../contact/ContactDetail";
import Welcome from "../home/Welcome";

let aboutUs =
  "Community-based organizations possess expertise in the delivery of accessible health and social services. However, they are often limited in their capacity to mobilize research knowledge. Universities possess expertise and resources for knowledge mobilization and are committed to serve their community. This website describes an educational program created by professors at Memorial University’s School of Social Work to foster collaboration between these two sectors. ";

export default function AboutPage() {
  //let headerSection = [<ConfHeader name='Publications' />];
  return (
    <>
      {/* headerSection*/}
      {/*Main section of the dashboard*/}
      <br />
      <Layout>
        <CardUI title='Welcome' showBiggerTitle={true}>
          <Welcome />
        </CardUI>

        <CardUI title='Welcome' body={aboutUs} showBiggerTitle={true} />
        <CardUI
          title='Our Mission and Vision'
          body={aboutUs}
          showBiggerTitle={false}
        />

        {/* <CardUI title='Contact US' body={""} showBiggerTitle={false}>
          <ContactDetail />
        </CardUI> */}

        <ContactInfo
          title='Contact US'
          showBiggerTitle={false}
          key={"AboutContactInfo-1"}
        />
      </Layout>
      {/***** Start Jumbotron ******/}
      {/*  <div className='jumbotron py-5 mb-0'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-7 col-lg-8 col-xl-9 my-auto'>
              <div>{<Partners />}</div>
            </div>
            <div className='col-md-5 col-lg-4 col-xl-3 pt-4'>
              <a href='' className='btn btn-primary btn-lg'>
                {" "}
                Contact US
              </a>
            </div>
          </div>
        </div>
      </div>
      */}
      {/***** End Jumbotron *****/}
    </>
  );
}
