import React from "react";
import { Link } from "react-router-dom";
// import {   Navbar,  Nav,  NavItem,  NavbarBrand,  Container} from "reactstrap";

export const UserHeading = () => {
  return (
    <div className='navbar' color='dark'>
      <div className='container'>
        <Link className='navbar-brand' to='/users'>
          Add User [useContext + useReducer]
        </Link>
        <nav>
          <div className='nav-item'>
            <Link className='btn btn-primary' to='/add-user'>
              Add User
            </Link>
          </div>
        </nav>
      </div>
    </div>
  );
};
