import React from "react";

export default function EventDetails() {
  //{   title,   date,   time,  location,  description,}
  let title = "Title ";
  let date = "Sep 28, 2020";
  let time = "10:00 AM - 4:00 PM";
  let location = "NL ";
  let description = "This is a sample description of the event";
  return (
    <>
      <h3>event-details</h3>
      <div className=''>
        {/* <h3>{title}</h3> */}
        <h3>{title}</h3>
        <p>
          Date: {date} Time: {time}
          <br /> Location: {location}
        </p>
        <p> {description} </p>
        <p> Presented by {}</p>
        {/* <a href={} target='_blank'>
            Go to event page
          </a> */}
      </div>
    </>
  );
}
