import React from "react";

function DisplayPaper({ paper }) {
  // let url = "https://www.researchgate.net/project/Exchange-2020";
  let authorShortNames = [];

  let format = /[!@#$%^&*()_+\-=\[\]{};':"\\|.<>\/?]+/;
  const checkSpecialChar = (str) => {
    return str.match(format) ? true : false;
  };

  const authorNameModify = () => {
    let authorNames = paper.author;
    authorNames = authorNames.split(/ and |',&'|,/g);
    for (let i = 0; i < authorNames.length; i++) {
      if (checkSpecialChar(authorNames[i])) {
        authorShortNames[i] = authorNames[i];
        // console.log("authorNames[i]:", authorNames[i]);
      } else {
        let nameSplit = authorNames[i].split(" ");
        let lastName = nameSplit[nameSplit.length - 1];
        let firstLetter = authorNames[i].replace(/\s+/g, "").slice(0, 1); // Remove the space then take the first Letter
        authorShortNames[i] = lastName + " " + firstLetter + ".";
      }
    }
  };

  authorNameModify();

  return (
    <>
      {/* <div>{paper.title}</div> */}

      <li key={paper.id} className='list-group-item'>
        <div
          style={{
            padding: "0px 5px",
            borderLeft: "0px solid green",
            backgroundColor: "white",
            color: "black",
          }}
        >
          <a
            href={paper.paper_link}
            rel='noopener noreferrer'
            target='_blank'
            style={{
              color: "black",
            }}
          >
            <b> {paper.title} </b>
          </a>
          <br />
          <div style={{ textAlign: "justify", textJustify: "interWord" }}>
            <span style={{ color: "green" }}>
              {authorShortNames.join(", ") + " "}
              <small></small>
            </span>
          </div>
          <div style={{ textAlign: "justify", textJustify: "interWord" }}>
            {paper.abstract.length < 170
              ? paper.abstract + ". "
              : paper.abstract}
            <a
              href={paper.paper_link}
              rel='noopener noreferrer'
              target='_blank'
              style={{ color: "gray" }}
            >
              [see more]
            </a>
          </div>
          <small> Keywords: {paper.keywords}</small>
        </div>
      </li>
    </>
  );
}

export default DisplayPaper;
