export const dataExchange2018 = [
  {
    id: 1,
    conference_id: 2,
    presentation_id: 1,
    title:
      "Sexual Exploitation, Trauma and Attachment: Implications for Community-Based Social Work",
    author: "Sarah Tremblett Lily Klein",
    abstract:
      "In this paper, we provide a brief summary of literature on the topics of attachment and trauma as they relate to sexual exploitation of youth and young adults, as well as relevant community-based intervention implications. We synthesize information from scholarly papers (research, reflections) with information gained from lived experiences, and analyze and discuss the knowledge gained in a way that provides realistic and practical implications for community-based organizations and professionals who work with youth who have experiences of sexual exploitation. Therefore, this project has incorporated the reflections and insights of survivors of sexual exploitation, and their opinions of what community-based organizations and professionals such as social workers can do to best support and assist youth who have experienced and/or are continuing to experience sexual exploitation.",
    keywords: "sexual exploitation, community-based, social work, leadership",
    first_page_number: 1,
    last_page_number: 10,
    additional_number: "",
    paper_link: "https://www.researchgate.net/project/Exchange-2018-conference",
    published_on: "2020-03-04T05:00:00.000Z",
    file_name: "",
    affiliation: "Memorial University of Newfoundland",
  },
  {
    id: 2,
    conference_id: 2,
    presentation_id: 1,
    title: "Identifying a User-Friendly Measure for Community Work",
    author: "Danielle Curwin Anda Adam",
    abstract:
      "This paper describes a collaborative research relationship that took place between a group of undergraduate social work students and Waypoints – a community-based organization that provides residential, employment and outreach, and family support services to children, youth and their families. We assessed and compared two alternative tools for assessing children and youth: the Rochester Evaluation of Asset Development for Youth (READY) and the Child and Adolescent Functional Assessment Scale (CAFAS), and then recommend one to Waypoints.",
    keywords:
      "rochester evaluation, research relationship, adolescent functional assessment",
    first_page_number: 1,
    last_page_number: 10,
    additional_number: "",
    paper_link: "https://www.researchgate.net/project/Exchange-2018-conference",
    published_on: "2020-03-04T05:00:00.000Z",
    file_name: "",
    affiliation: "Memorial University of Newfoundland",
  },
  {
    id: 3,
    conference_id: 2,
    presentation_id: 1,
    title:
      "Nutrition and Child Wellness: Considerations for Social Service Providers",
    author: "Candace Hopkins",
    abstract:
      "This paper is a small effort to support the continuing education of practitioners in matters related to nutrition and child development. As such, it reviews the important role that a healthy diet plays in supporting optimal development, examines the lack of nutritional education that is provided to many social service providers, articulates recommendations to address this educational gap, and suggests ideas for how service providers can incorporate nutritional-based knowledge into their practices.",
    keywords:
      "nutrition, child development, nutritional-based knowledge , healthy diet",
    first_page_number: 1,
    last_page_number: 10,
    additional_number: "",
    paper_link: "https://www.researchgate.net/project/Exchange-2018-conference",
    published_on: "2020-03-04T05:00:00.000Z",
    file_name: "",
    affiliation: "Memorial University of Newfoundland",
  },
  {
    id: 4,
    conference_id: 2,
    presentation_id: 1,
    title:
      "Food Insecurity in Canada: A Reflection on the Efficacy of Current Programs and Policies",
    author: "Caitlyn Tobin",
    abstract:
      "In this review, I will be reflecting on the findings of studies and reports that are focused on the state of food insecurity and the means in place to combat it. I will be looking at the adequacy levels of various government income support programs as well as community-based food centred initiatives.",
    keywords: "food insecurity, centred initiatives,  income support programs ",
    first_page_number: 1,
    last_page_number: 10,
    additional_number: "",
    paper_link: "https://www.researchgate.net/project/Exchange-2018-conference",
    published_on: "2020-03-04T05:00:00.000Z",
    file_name: "",
    affiliation: "Memorial University of Newfoundland",
  },
  {
    id: 5,
    conference_id: 2,
    presentation_id: 1,
    title: "Critical reflection on food assistance programs",
    author: "Candace Budgell",
    abstract:
      "This critical reflection paper briefly explores the place of service users in current conceptions of food programs and policies through the lenses of empowerment theory and Gaventa's “Power Cube”.",
    first_page_number: -1,
    last_page_number: 10,
    additional_number: "",
    paper_link: "https://www.researchgate.net/project/Exchange-2018-conference",
    published_on: "2020-03-04T05:00:00.000Z",
    file_name: "",
    affiliation: "Memorial University of Newfoundland",
  },
  {
    id: 6,
    conference_id: 2,
    presentation_id: 1,
    title:
      "The Relationship Between Housing Instability and HIV-Risk Behaviors",
    author: "Riley Hunt",
    abstract:
      "This paper will review a number of studies on the interconnectedness between HIV-risk behaviours and experiencing housing instability while highlighting the social factors that influence the increased vulnerability of this population. The implications of these findings at the micro and macro levels will also be considered.",
    first_page_number: -1,
    last_page_number: 10,
    additional_number: "",
    paper_link: "https://www.researchgate.net/project/Exchange-2018-conference",
    published_on: "2020-03-04T05:00:00.000Z",
    file_name: "",
    affiliation: "Memorial University of Newfoundland",
  },
  {
    id: 7,
    conference_id: 2,
    presentation_id: 1,
    title:
      "Issues Affecting People Aging with Intellectual Disabilities and the Challenges of Caring for Them",
    author: "Chris Keough, Taylor Peddle & Erin Kenny",
    abstract:
      "For this research paper, we examine the implications of ageing populations and its implications for people with intellectual disabilities, notably caregiver burden of aging adults who care for their adult sons and daughters with intellectual disabilities.",
    first_page_number: -1,
    last_page_number: 10,
    additional_number: "",
    paper_link: "https://www.researchgate.net/project/Exchange-2018-conference",
    published_on: "2020-03-04T05:00:00.000Z",
    file_name: "",
    affiliation: "Memorial University of Newfoundland",
  },
  {
    id: 8,
    conference_id: 2,
    presentation_id: 1,
    title: "Strength in Numbers: Social Support and PTSD Coping",
    author: "Jared M. Davis",
    abstract:
      "In this research summary, I review concepts of social support most directly associated with PTSD diagnosis and coping, outcomes related to treatment and coping styles in the context of social support and PTSD coping, and the unique conditions and supportive factors associated with clients diagnosed with PTSD in rural and urban communities, as well as refugee populations. I conclude by discussing directions for future social work practice and research to help improve clients’ PTSD symptoms and ability to cope through the use of social support.",
    first_page_number: -1,
    last_page_number: 10,
    additional_number: "",
    paper_link: "https://www.researchgate.net/project/Exchange-2018-conference",
    published_on: "2020-03-04T05:00:00.000Z",
    file_name: "",
    affiliation: "Memorial University of Newfoundland",
  },
  {
    id: 9,
    conference_id: 2,
    presentation_id: 1,
    title:
      "One in Five: How Child Protective Services is Failing Youth in Foster Care",
    author: "Emma Fifield",
    abstract:
      "The focus of this review paper is to examine the relationship between youth who transition out of foster care and their experiences of homelessness. This paper will then relate the research findings to Newfoundland and Labrador (NL) and discuss considerations for service providers.",
    first_page_number: -1,
    last_page_number: 10,
    additional_number: "",
    paper_link: "https://www.researchgate.net/project/Exchange-2018-conference",
    published_on: "2020-03-04T05:00:00.000Z",
    file_name: "",
    affiliation: "Memorial University of Newfoundland",
  },
  {
    id: 10,
    conference_id: 2,
    presentation_id: 1,
    title: "Effectiveness of Foster Parent Training Programs",
    author: "Hailee Alyssa Hearn",
    abstract:
      "The focus of this paper is to briefly review recent research evaluating training programs for foster parents with an objective of determining their effectiveness in terms of fostering parent skills and child outcomes, and reflecting on what practice and policies might be suitable for this province.",
    first_page_number: -1,
    last_page_number: 10,
    additional_number: "",
    paper_link: "https://www.researchgate.net/project/Exchange-2018-conference",
    published_on: "2020-03-04T05:00:00.000Z",
    file_name: "",
    affiliation: "Memorial University of Newfoundland",
  },
  {
    id: 11,
    conference_id: 2,
    presentation_id: 1,
    title:
      "Supportive Housing Research and Implication for Social Work Practice",
    author: "Carradine Lambert",
    abstract:
      "This paper highlights various research findings regarding the benefits of supportive housing regimes and discuss implications for federal and provincial social welfare policies and for social service practice.",
    first_page_number: -1,
    last_page_number: 10,
    additional_number: "",
    paper_link: "https://www.researchgate.net/project/Exchange-2018-conference",
    published_on: "2020-03-04T05:00:00.000Z",
    file_name: "",
    affiliation: "Memorial University of Newfoundland",
  },
  {
    id: 12,
    conference_id: 2,
    presentation_id: 1,
    title: "Supportive and Transitional Housing for Indigenous Peoples",
    author: "Alyssa King",
    abstract:
      "This paper provides a brief overview of some traditional models of transitional and supportive housing and offers suggestions as to ways we can move forward to meeting Indigenous peoples’ housing needs.",
    first_page_number: -1,
    last_page_number: 10,
    additional_number: "",
    paper_link: "https://www.researchgate.net/project/Exchange-2018-conference",
    published_on: "2020-03-04T05:00:00.000Z",
    file_name: "",
    affiliation: "Memorial University of Newfoundland",
  },
  {
    id: 13,
    conference_id: 2,
    presentation_id: 1,
    title: "Children in Foster Care with Disabilities: Gaps in Policy",
    author: "Ellen Maher, Stephen Ellenbogen, and Keith Power",
    abstract:
      "This paper briefly explores placement outcomes of children with disabilities living in foster care and policy issues within child protection services, and then offers recommendations for organizations that serve this population.",
    first_page_number: -1,
    last_page_number: 10,
    additional_number: "",
    paper_link: "https://www.researchgate.net/project/Exchange-2018-conference",
    published_on: "2020-03-04T05:00:00.000Z",
    file_name: "",
    affiliation: "Memorial University of Newfoundland",
  },
];
