import React from "react";
import CSLCard from "../../components/card/CSLCard";
import img1 from "../../assets/images/kt_04_small.png";
import img2 from "../../assets/images/kt_06_small.png";
import img3 from "../../assets/images/kt_02_small.png";
//import img3 from "../../assets/images/kt_10_small.jpg";

const CSL = (props) => {
  return (
    <>
      {/* <div className='card' style={{ boxShadow: " 0px 0px #ffffff" }}> */}
      <div className='card text-center shadow mb-0'>
        {props.showBiggerTitle && (
          <div className='card-header conference-bg-color mb-0'>
            <div className='card-title new-card-title'>
              <b>{props.title} </b>
            </div>
          </div>
        )}
        {/* /************************ */}
        {!props.showBiggerTitle && (
          <div>
            <h5 className='card-title my-3'> {props.title}</h5>
            <div className='border-top border-primary w-75 mx-auto my-3'></div>
          </div>
        )}
        {/* /************************ */}

        <div className='row my-3'>
          <div className='col-md-4 my-2'>
            <CSLCard
              image={img1}
              title='CSL Assignments'
              link_to='/courses'
              instructor=''
            />
          </div>
          <div className='col-md-4 my-2'>
            <CSLCard
              image={img2}
              link_to='/projects'
              title='Long-term Partnerships'
              instructor=''
            />
          </div>
          <div className='col-md-4 my-2'>
            <CSLCard
              image={img3}
              link_to='/outcomes'
              title='FAQ'
              instructor=''
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CSL;
