export function formatDate(dateString) {
  if (dateString) {
    var t = dateString.split(/[- :]/);
    var y = t[0].split("-");
    var m = t[1].split("-");
    var d = t[2].split("T").slice(0, 1);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = `${months[m - 1]} ${d}, ${y}`;
    return date;
  } else {
    return "TBA";
  }
}

export function formatTime(timeString) {
  if (timeString) {
    var t = timeString.split(/[- :]/);
    var h = t[0].split("-");
    var m = t[1].split("-");
    var hm = h < 12 ? h + ":" + m : h - 12 + ":" + m;
    var result = h < 12 ? hm + " AM" : hm + " PM";
    //console.log(result)
    return result;
  } else {
    return "TBA";
  }
}
