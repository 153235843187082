import Axios from "axios";
import authHeader from "./authHeader";

export const getPublicContent = async (API_SITE_URL) => {
  return await Axios.get(API_SITE_URL, { headers: authHeader });
};

export const getCurrentUser = () => {
  const result = JSON.parse(localStorage.getItem("user"));
  // console.log(user);
  if (result) return result.user;
  else return {};
};

const userService = {
  getPublicContent: getPublicContent,
  getCurrentUser: getCurrentUser,
};

export default userService;
