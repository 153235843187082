import React, { useContext } from "react";
import { GlobalContext } from "../../context/StateProvider";
import { Link } from "react-router-dom";
import { formatDate } from "../common/FormatDateTime";

// import { ListGroup, ListGroupItem, Button } from "reactstrap";

const UserList = () => {
  const { users, removeUser } = useContext(GlobalContext);

  return (
    <div className='list-group mt-4'>
      {users.length > 0 ? (
        <>
          <table className='table table-striped table-hover'>
            <thead>
              <tr>
                <th scope='col'>SL</th>
                <th scope='col'> Name</th>
                <th scope='col'> Email</th>
                <th scope='col'> Joined </th>
                <th scope='col'> </th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, i) => (
                <tr key={i}>
                  <th scope='row'>{i + 1}</th>
                  <td>
                    {user.first_name} {user.last_name}
                  </td>
                  <td>{user.email}</td>
                  <td> {formatDate(user.created_on)}</td>
                  <td>
                    <Link
                      to={`/edit-user/${user.id}`}
                      color='warning'
                      className='btn btn-warning mr-2'
                    >
                      Edit
                    </Link>
                    <button
                      className='btn btn-danger'
                      onClick={() => removeUser(user.id)}
                      color='danger'
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* ...............................  */}
          {users.map((user, i) => (
            <div className='list-group-item d-flex' key={user.id}>
              <strong>
                {i + 1}. &nbsp;&nbsp; {user.first_name} {user.last_name}{" "}
                &nbsp;&nbsp; {user.email}
              </strong>
              <div className='ml-auto'>
                <Link
                  to={`/edit-user/${user.id}`}
                  color='warning'
                  className='btn btn-warning mr-2'
                >
                  Edit
                </Link>
                <button
                  className='btn btn-danger'
                  onClick={() => removeUser(user.id)}
                  color='danger'
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
          {/* ...............................  */}
        </>
      ) : (
        <h4 className='text-center'>No Users</h4>
      )}
    </div>
  );
};

export default UserList;
