import React from "react";
import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import DashboardLayout from "../layout/DashboardLayout";
import { useEventState } from "../../context/EventProvider";
import CardContent from "../common/CardContent";

// import { useGlobalState } from "../context/StateProvider";
//import PagesMainSection from "../PagesMainSection";
// import CKEditorTest from "../CKEditorTest"; <CKEditorTest />

export default function EditEvent(props) {
  const [name, setName] = useState(" ");
  const [description, setDescription] = useState("");
  const [presenters, setPresenters] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [location, setLocation] = useState("");
  const [joiningLink, setJoiningLink] = useState("");

  const [eventList, setEventList] = useState([]);
  // const [cEvent, setCEvent] = useState([]);

  // const [state, dispatch] = useGlobalState();
  const { events } = useEventState();

  const [selectedEvent, setSelectedEvent] = useState({
    // id: '',
    // name: ''
  });

  const history = useHistory();
  const currentEventId = props.match.params.id;
  console.log("currentEventId: " + parseInt(currentEventId));

  useEffect(() => {
    const eventId = currentEventId;
    const currentEvent = events.find((event) => event.id === eventId);
    // console.log("eventId = " + events.length  + '  selectedEvent = '+ selectedEvent.title)
    setSelectedEvent(currentEvent);
  }, [currentEventId, events]);

  // console.log("[From EventEdit page :] events.length = " + events.length);
  // console.log(
  //   "[From EventEdit page :] selectedEvent = " + selectedEvent.length);

  //********** Sending data to Back-end (then we will Insert content to Database) by POST request****** */
  const submitContent = (e) => {
    e.preventDefault();
    setEventList([
      ...eventList,
      {
        name: name,
        description: description,
        presenters: presenters,
        event_date: eventDate,
        start_time: startTime,
        end_time: endTime,
        location: location,
        joining_link: joiningLink,
      },
    ]);
    // setCEvent([
    //   {
    //     title: title,
    //     description: description,
    //     presenters: presenters,
    //     event_date: eventDate,
    //     start_time: startTime,
    //     end_time: endTime,
    //     location: location,
    //     joining_link: joiningLink,
    //   },
    // ]);
    history.push("/dashboard-events");
  };

  return (
    <>
      <DashboardLayout page_name='' inner_page_name='Edit Event'>
        <CardContent title='Edit Event'>
          <div className='form'>
            {/* <form> */}
            <div className='form-group'>
              <label>Event Name:</label>
              <input
                type='text'
                className='form-control'
                placeholder={"Please enter an event name*"}
                // defaultValue={"Event Name*"}
                onChange={(e) => setName(e.target.value)}
              />

              {/* <input type="text" value={selectedEvent.title} onChange={(e) =>  setName(e.target.value)}    name="title" placeholder="Enter user" required></input> */}

              {/* <input type="text" value={selectedEvent.title} onChange={onchange} name="title" placeholder="Enter user" required></input> */}
              {/* <span> {console.log('selectedEvent.title: '+ selectedEvent.title)} </span> */}

              <label>Event Description:</label>
              <textarea
                rows='5'
                className='form-control'
                placeholder='Please enter an event description*'
                // defaultValue='Event Description*'
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />

              <label>Presenters: </label>
              <input
                type='text'
                className='form-control'
                placeholder='Please enter presenter name(s)*'
                // defaultValue='Presenters*'
                onChange={(e) => {
                  setPresenters(e.target.value);
                }}
              />

              <label>Date: </label>
              <input
                type='date'
                className='form-control'
                placeholder='Add date...'
                defaultValue='2020-10-30 '
                onChange={(e) => {
                  setEventDate(e.target.value);
                }}
              />

              <label>Start Time </label>
              <input
                type='time'
                className='form-control'
                placeholder='Add time...'
                defaultValue=' 12:30:00'
                onChange={(e) => {
                  setStartTime(e.target.value);
                }}
              />

              <label>End Time: </label>
              <input
                type='time'
                className='form-control'
                placeholder='Add time...'
                defaultValue=' 14:30:00'
                onChange={(e) => {
                  setEndTime(e.target.value);
                }}
              />

              <label>Location: </label>
              <input
                type='text'
                className='form-control'
                placeholder='Add location...'
                defaultValue=' Memorial University'
                onChange={(e) => {
                  setLocation(e.target.value);
                }}
              />

              <label>Joining link: </label>
              <input
                type='text'
                className='form-control'
                placeholder='Add link...'
                defaultValue=' https://meet.google.com/pnx-vecc-axq'
                onChange={(e) => {
                  setJoiningLink(e.target.value);
                }}
              />

              {/* <div className='checkbox'>
                      <label>
                        <input type='checkbox' checked /> Published
                      </label>
                    </div>
                    <div className='form-group'>
                      <label>Meta Tags</label>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Add Some Tags...'
                        value='tag1, tag2'
                      />
                    </div> */}

              {/* <input
                      type='submit'
                      className='btn btn-success'
                      value='Submit'
                    /> */}
              {/*******  Submit Button  *******/}
              <button
                type='button'
                className='btn btn-info mt-3'
                onClick={submitContent}
              >
                Submit
              </button>
              <Link to='/dashboard-events' className='btn btn-danger ml-3 mt-3'>
                Cancel
              </Link>
            </div>
            {/* </form> */}
          </div>
        </CardContent>
      </DashboardLayout>
    </>
  );
}
