import React from "react";
import "./button-style.css";

const Button = (props) => (
  <button
    className={["Button", props.btn_type].join(" ")}
    onClick={props.clicked}
  >
    {props.children}
  </button>
);

export default Button;
