export default function errorhandler(err) {
  // console.log("My errorHandler is called:");
  if (err.response) {
    // Server responded with a status other than 200 range
    console.log(
      "Problem with Response:",
      "\n ",
      "Response with status: ",
      err.response.status,
      "\n ",
      "Response with data: ",
      err.response.data,
      "\n ",
      "Response with header: ",
      err.response.headers
      // "\n ",
      // "Response message:",
      // err.message
    );
    // console.log("Data: ", err.response.data);
    // console.log("Status: ", err.response.status);
    // console.log("Header: ", err.response.headers);
    if (err.response.status === 404) {
      alert("Error: Page Not Found");
    }
  } else if (err.request) {
    // Request was made but no response
    console.error("Problem with Request:", err.request);
  } else {
    console.error("Error message:", err.message);
  }
}
