import React from "react";
export default function AdminHeader(props) {
  return (
    <>
      <header id='header'>
        <div className='container my-0'>
          <div className='row'>
            <div className='col-md-12 my-3'>
              <h1 className='text-center'>
                <span className='gear-fill'>
                  <span className='fa fa-cog'></span>
                </span>
                Admin Area{" "}
                <small style={{ color: "red" }}> {props.title} </small>
              </h1>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
