import React from "react";
import { Link } from "react-router-dom";

export default function Copyright() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  return (
    <>
      {/******  Start Copyright ******/}
      <div className='copyright text-light text-center py-3'>
        <p>
          &copy;{" "}
          <Link
            to={{
              pathname: "https://www.researchexchange.ca/",
            }}
            target='_blank'
          >
            <strong> ResearchExchange.ca </strong>
          </Link>
          {getCurrentYear()} | Developed by Abu N. Sarker
        </p>
      </div>
      {/******  End Copyright ******/}
    </>
  );
}
