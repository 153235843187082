import React from "react";
import SiteOverview from "./common/SiteOverview";
import LatestUsers from "./common/LatestUsers";
import DashboardLayout from "./layout/DashboardLayout";

const Dashboard = () => {
  return (
    <>
      <DashboardLayout page_name=''>
        <SiteOverview />,
        <LatestUsers />,
      </DashboardLayout>
    </>
  );
};

export default Dashboard;
