import React from "react";
import "./CSLCard-style.css";
import { NavLink } from "react-router-dom";

const CSLCard = (props) => {
  return (
    <>
      <div className='card text-center shadow-none bg-light'>
        {/* <div className='card text-center shadow'> */}
        {props.image && (
          <div className='overflow'>
            <img src={props.image} alt='ImageSrc' className='card-img-top' />
          </div>
        )}

        <div className='card-body text-dark'>
          <h5 className='card-title'> {props.title}</h5>
          <p className='card-text text-secondary'> {props.instructor}</p>

          <div>{props.children}</div>

          <NavLink to={props.link_to} className='btn btn-outline-success'>
            {" "}
            See more
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default CSLCard;
