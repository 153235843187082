import React, { useState } from "react";
import "./resources-style.css";

import SearchForm from "./SearchForm";
import Videos from "./Videos";
import Sidebar from "../../components/common/Sidebar";
import Section from "../../components/common/Section";
import Breadcrumb from "../../components/common/Breadcrumb";
import Modal from "../../components/UI/modal/Modal";
import ModalContent from "../../components/UI/modal/ModalContent";
import Layout from "../layout/Layout";

// import CRUD from "../../components/common/CRUD";

// import PhotoGallery from "./PhotoGallery";
// import LayoutImageGallery from "../photo_gallery/LayoutImageGallery";
export default function LayoutResourcesPage() {
  // Modal handler--------------
  const [showModal, setShowModal] = useState(false);
  const modalOpenHandler = () => {
    setShowModal(true);
  };

  const modalClosedHandler = () => {
    setShowModal(false);
  };
  const modalContinueHandler = () => {
    alert("You continue!");
  };
  // End Modal handler------------------

  let bodySections = [
    <SearchForm title='Search Area' showTitle={true} key={"ResourcesSF-1"} />,
    <Section title='Community' body='Sample text' key={"ResourcesSection-1"} />,
    <Videos title='Videos' body='Sample text' key={"ResourcesVideos-1"} />,
    // <CRUD title='CRUD Operation' key={"ResourcesCRUD-1"} />,
    // <PhotoGallery title='Photo Gallery' />,
    // <LayoutImageGallery title='Photo Gallery' />,
  ];

  return (
    <>
      {/* {headerSection} */}
      {/* <Breadcrumb name='Resources' /> */}
      <br />
      <Layout>
        {/* {bodySections} */}
        {/* <SearchForm
          title='Search Area'
          showTitle={true}
          key={"ResourcesSF-1"}
        /> */}
        {/* <Section
          title='Community'
          body='Sample text'
          key={"ResourcesSection-1"}
        /> */}

        <Videos
          title='Exchange 2020 Conference Videos'
          body=''
          key={"ResourcesVideos-1"}
        />
        {/*  Modal handler ------------------- */}
        <Modal show={showModal} modalClosed={modalClosedHandler}>
          <ModalContent
            modalClosed={modalClosedHandler}
            modalContinue={modalContinueHandler}
          />
        </Modal>
        {/* <button
          type='button'
          className='btn btn-warning'
          onClick={modalOpenHandler}
        >
          Open Modal
        </button> */}
        {/*  End Modal handler ------------------- */}
      </Layout>

      {/*====OR============  */}
      {/* Sidebar and Body-section */}
      {/* <section id='dashboard-main'>
        <div className='container'>
          <div className='row'>
            <div className='col col-md-9 pl-0'>
              {bodySections}
              <Modal show={showModal} modalClosed={modalClosedHandler}>
                <ModalContent
                  modalClosed={modalClosedHandler}
                  modalContinue={modalContinueHandler}
                />
              </Modal>
              <button
                type='button'
                className='btn btn-warning'
                onClick={modalOpenHandler}
              >
                Open Modal
              </button>
            </div>
            <div className='col-md-3 pr-0'>
              <Sidebar />
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}
