import React, { useState } from "react";
import Pagination from "./Pagination";
import CardUI from "../../components/card/CardUI";

export default function PaperCard(props) {
  return (
    <>
      {/* <div id='conference-article'>
        <div className='card my-3'>
          <div className='card-header'>
            <div className='card-title new-card-title'>{props.title}</div>
          </div>
          <div className='card-body mx-0 my-0 py-0'>
            <Pagination listOfItems={props.papers} postPerPage={5} />
          </div>
        </div>
      </div> */}
      {/* ------------------- */}
      <CardUI title={props.title} body={""} showBiggerTitle={false}>
        <Pagination listOfItems={props.papers} postPerPage={15} />
      </CardUI>
    </>
  );
}
