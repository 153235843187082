import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getEvents, deleteEvent } from "../../services/eventService";
import errorHandler from "../../services/errorHandler";
import { formatDate, formatTime } from "../common/FormatDateTime";

// import { useHistory} from "react-router-dom";
// import { v4 as uuid } from "uuid";
// import Axios from "axios";

export default function EventMainSection(props) {
  const [eventList, setEventList] = useState([]);
  const [loading, setLoading] = useState(false);

  //*** GET Events ****** */
  useEffect(() => {
    try {
      setLoading(true);
      getEvents().then((res) => {
        // console.log(res.data);
        setEventList(res.data);
      });
      setLoading(false);
    } catch (err) {
      errorHandler(err);
    }
  }, []);

  //*** DELETE ****** */
  const deleteContent = (event_id) => {
    deleteEvent(event_id)
      .then(() => {
        alert("Successfully deleted the item -" + event_id);
        delete eventList.id;
        setEventList(eventList);
      })
      .catch((err) => errorHandler(err));
    return;
  };

  //const fetchEvents = async () => {
  // async function fetchEvents() {
  //   try {
  //     setLoading(true);
  //     const res = await Axios.get("/api/events/get-events");
  //     setEventList(res.data);
  //     setLoading(false);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  // useEffect(() => {
  //   fetchEvents();
  // }, []); //if [], run once when the row loads, and do not run again

  // const deleteContent = (event_id) => {
  //   Axios.delete(`/api/events/delete-event/${event_id}`).then(() => {
  //     alert("Successfully Deleted the item -" + event_id);
  //     console.log(
  //       "ID - " + parseInt(event_id) + " has sent for deleting the event."
  //     );
  //     delete eventList.id;
  //     setEventList(eventList);
  //   });
  //   return;
  // };

  //**********Send PUT request to Back-end for updating a content from database****** */
  // const updateContent = (content_id) => {
  //   Axios.put(`http://localhost:8000/api/update-content/${content_id}`, {
  //     //content_id: content_id,
  //     question: newQuestion,
  //   }).then(() => {
  //     //alert("Successfully Updated!");
  //     console.log(
  //       "ID-" + content_id + " has sent for updating the content:" + newQuestion
  //     );
  //   });

  //   console.log(
  //     "ID-" + content_id + " has sent for updating the content:" + newQuestion
  //   );
  //   setNewQuestion("");
  // };

  return (
    <>
      {/*----  start Users section <SiteOverview />-----*/}
      <div className='card mb-3' style={{ boxShadow: "0 0 white" }}>
        <div className='card-header main-color-bg '>
          <div className='card-title new-card-title'> Events</div>
        </div>
        {/*---- Start User body-----*/}
        <div className='card-body mx-4'>
          <div className='row'>
            <div className='col-md-12'>
              <input
                className='form-control'
                type='text'
                placeholder='Filter Users...'
              />
            </div>
          </div>
          <br />

          {loading && <h6 className='text-center'> Loading ... </h6>}

          {!loading && (
            <>
              {/* ............................. */}
              <div>
                <table className='table table-striped table-hover mt-0'>
                  <thead>
                    <tr>
                      <th scope='col'>SL</th>
                      <th scope='col'> Date</th>
                      <th scope='col'> Time</th>
                      <th scope='col'> Event</th>
                      <th scope='col'> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventList.map((data, index) => {
                      return (
                        <tr key={index}>
                          <th scope='row'>{index + 1}.</th>
                          <td>{formatDate(data.start_date)}</td>
                          <td>{formatTime(data.start_time)}</td>
                          <td>
                            <a
                              href={data.joining_link}
                              rel='noopener noreferrer'
                              target='_blank'
                            >
                              {data.name}
                            </a>
                          </td>
                          <td>
                            <Link
                              to={`/dashboard-edit-event/${data.id}`}
                              className='btn btn-warning mr-2'
                              event={data}
                            >
                              Edit
                            </Link>

                            <button
                              className='btn btn-danger'
                              onClick={() => deleteContent(data.id)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {/* ............................. */}
              {/* <div className="list-group mt-4">
                  {events.length > 0 ? (
                      <>
                        {events.map((event, index) => (
                          <div className="list-group-item d-flex" key={event.event_id}>
                                                                 
                              <div>
                                  <strong>{index +1 }{".  "}  &nbsp;&nbsp; </strong>
                                  <span >{formatDate(event.event_date) + "     "} &nbsp;&nbsp; {formatTime(event.start_time) +  "     "} &nbsp;&nbsp;</span>
                                   <strong>{event.title} &nbsp;&nbsp; </strong> 
                              </div>
             
                              <div className="ml-auto">
                                <Link to={`/edit-event/${event.event_id}`} color="warning" className="btn btn-warning mr-1">Edit</Link>
                                <button className="btn btn-danger" onClick={() => removeEvent(event.event_id)} color="danger">Delete</button>
                              </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <h4 className="text-center">No Events</h4>
                  )}
                </div> */}
              {/* ................... */}
            </>
          )}
        </div>
      </div>
      {/*----  End Users section <SiteOverview />----*/}
    </>
  );
}
