//import React from 'react';
import {ADD_EVENT, REMOVE_EVENT, EDIT_EVENT} from "./types";
//import {ADD_USER, REMOVE_USER, EDIT_USER} from "./types";

export default function EventReducer(state, action) {
    switch (action.type) {
      case ADD_EVENT:
        return {
          ...state,
          events: [action.payload, ...state.events]
        }

      case REMOVE_EVENT:
        return {
          ...state,
          events: state.events.filter(event => {
            return event.id !== action.payload;
          })
        }

      case EDIT_EVENT:
        const updateEvent = action.payload;
        const updateEvents = state.events.map(event => {
          if (event.id === updateEvent.id) {
            return updateEvent;
          }
          return event;
        })
        return {
          ...state,
          events: updateEvents
        }
  
      default:
        return state;
    }
  }


// export default (state, action) => {
//     switch (action.type) {
//       case DELETE_EVENT:
//         return {
//           ...state,
//           events: state.events.filter(event => {
//             return event.id !== action.payload;
//           })
//         }
//       case ADD_EVENT:
//         return {
//           ...state,
//           events: [...state.events, action.payload]
//         }
//       case EDIT_EVENT:
//         const updateEvent = action.payload;
  
//         const updateEvents = state.events.map(event => {
//           if (event.id === updateEvent.id) {
//             return updateEvent;
//           }
//           return event;
//         })
//         return {
//           ...state,
//           events: updateEvents
//         }
  
//       default:
//         return state;
//     }
//   }


//   const addEvent =(event, state)=>{
//     //src: https://www.youtube.com/watch?v=cjBm0HnYcqw&feature=share&ab_channel=Classsed
//     }
    
    
    
//     export default (state, action)
//     switch(action.type){
//         case ADD_EVENT:
//             return addEvent(action.payload, state);
//         default:
//             return state;
//     }