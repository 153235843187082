import React from "react";

export default function LatestUsers() {
  return (
    <>
      <div className='card my-3' style={{ boxShadow: "0 0 white" }}>
        <div className='card-header'>
          <div className='card-title new-card-title'>Latest Users</div>
        </div>
        <div className='card-body'>
          <table className='table table-striped table-hover'>
            <thead>
              <tr>
                <th scope='col'>SL</th>
                <th scope='col'> Name</th>
                <th scope='col'> Email</th>
                <th scope='col'> Joined </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope='row'>1</th>
                <td>Mark Otto</td>

                <td>ans@gmail.com</td>
                <td> Sep 09, 2020</td>
              </tr>
              <tr>
                <th scope='row'>2</th>
                <td>Jacob Thornton</td>
                <td>jcobe@gmail.com</td>
                <td> Sep 09, 2020</td>
              </tr>
              <tr>
                <th scope='row'>3</th>
                <td>Larry Bird</td>
                <td>larry@gmail.com</td>
                <td> Sep 09, 2020</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
