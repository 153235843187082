import React from "react";
// import "./events-style.css";

import Layout from "../layout/Layout";
import EventsListing from "./EventsListing";
import Breadcrumb from "../../components/common/Breadcrumb";

// import Section from "../../components/common/Section";
// import UseReducerDemoTodo from "../hooks/UseReducerDemoTodo";
// import UseReducerDemoCount from "../hooks/UseReducerDemoCount";
// import FetchData from "../../components/common/FetchData";
// import GetEvents from "./GetEvents";

// import AddUser from "../dashboard/users/AddUser";
import UserHome from "../../dashboard/users/UserHome";

//import GetData from "../common/GetData";
//import SendData from "../common/SendData";
//import CRUD from "../common/CRUD";
//import SearchForm from "./SearchForm";

export default function LayoutEventPage() {
  let bodySections = [
    // <CRUD title='CRUD Operation' />,
    // <SendData title='Send DATA' body='' />,
    //<GetData title='Get DATA' body='' key={"ReactGetData-1"} />,
    // <EventsListing key={"ReactEventsListing-1"} />,
    // <GetEvents title='Get Events' body='' key={"ReactGetEvents-1"} />,
    // <FetchData title='Fetch DATA' body='' key={"ReactGetData-1"} />,
    // <Section
    //   title='React Hooks'
    //   body={[
    // <UseReducerDemoTodo key={"ReactSection-1-1"} />,
    // <hr key={"ReactHR-1-1"} />,
    // <UseReducerDemoCount key={"ReactSection-1-2"} />,
    // <hr key={"ReactHR-1-2"} />,
    //<UserHome key={"UserHome-1"} />,
    // <AddUser key={"AddUser-1"} />,
    //   ]}
    //   key={"ReactSection-1"}
    // />,
  ];

  return (
    <>
      {/* {headerSection} */}
      <Breadcrumb name='Events Page' />
      {/* sidebar and body section */}
      <Layout>
        <EventsListing />
        <UserHome />
      </Layout>
    </>
  );
}
