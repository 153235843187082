import React from "react";
import { Link } from "react-router-dom";

import Copyright from "./Copyright";
import logo from "../../assets/images/logo/RE-logo-footer.png";
//import logo from "../../assets/images/logo/KE-logo-5.png";

export default function Footer() {
  return (
    <>
      {/******  Start Footer *****/}
      <footer>
        <div className='container'>
          <div className='row text-light text-center py-4 justify-content-center'>
            <div className='col-md-12 col-sm-8 col-lg-8'>
              <img src={logo} alt='' />
              <p>
                This program is supported in part by funding from the Social
                Sciences and Humanities Research Council. Other funding support
                was received from the Memorial University Office of Public
                Engagement Accelerator and Quick Start Grants Programs.
              </p>
              <ul className='social pt-3'>
                {/* <!--target="_blank" ==> open a new tab -->*/}
                <li>
                  <Link
                    to={{
                      pathname: "https://www.facebook.com/",
                    }}
                    target='_blank'
                  >
                    <span className='fa fa-facebook'></span>
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "https://twitter.com/?lang=en",
                    }}
                    target='_blank'
                  >
                    <span className='fa fa-twitter'></span>
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "https://www.instagram.com/?hl=en",
                    }}
                    target='_blank'
                  >
                    <span className='fa fa-instagram'></span>
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "https://youtu.be/yaPTU59tHs4/",
                    }}
                    target='_blank'
                  >
                    <span className='fa fa-youtube'></span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      {/******  End Footer ******/}
      <Copyright />
    </>
  );
}
