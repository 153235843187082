import React from "react";
import ReactPlayer from "react-player";
import VideoPlayer from "../../components/common/VideoPlayer";

const Videos = (props) => {
  return (
    <>
      <section>
        {/*---- start the video section-----*/}
        <div className='card ' style={{ boxShadow: "0 0 white" }}>
          {/*---- Title-----*/}
          <div className='card-header conference-bg-color mb-0'>
            <div className='card-title new-card-title'>{props.title}</div>
          </div>

          {/*---- Start the body part  -----*/}
          <div className='card-body'>
            {/*<div className='border-top border-primary w-75 mx-auto my-3'></div> */}
            <p className='text-justify px-3'>{props.body}</p>
            {/* Render a YouTube video player */}
            {/* <ReactPlayer controls url='https://youtu.be/yaPTU59tHs4' /> */}
            {/* <VideoPlayer url='https://youtu.be/yaPTU59tHs4' description='' /> */}

            <div className='container'>
              <div className='row'>
                <div className='col col-md-5.5 pl-0 mr-0'>
                  <VideoPlayer
                    url='https://youtu.be/yaPTU59tHs4'
                    description='Welcome remarks and opening address are delivered by Dr. Stephen Ellenbogen at Exchange 2020 Conference, Memorial University, March 4, 2020.'
                  />
                  <br />
                  <VideoPlayer
                    url='https://youtu.be/TqCrYiDfC0k'
                    description='Speech delivered by Beckie Huston and Bianca Martin on Using an indigenous Ideological Framework to Analyse Social Work Legislation at Exchange 2020 Conference, Memorial University, March 4, 2020'
                  />
                  <br />
                  <VideoPlayer
                    url='https://youtu.be/5191fUSKRSs'
                    description='Speech delivered by Claire Katrina Cherney on Placement Stability in Care: A case study of a collaborative agency-university partnership at Exchange 2020 Conference, Memorial University, March 4, 2020.'
                  />
                  <br />
                  <VideoPlayer
                    url='https://youtu.be/S741x3u9bvU'
                    description='Speech delivered by Amy-Anne Smith on Indigenous child welfare policy and practice: The efficacy and challenges of devolution and custom adoption at Exchange 2020 Conference, Memorial University, March 4, 2020.'
                  />
                </div>
                <div className='col col-md-1' />
                <div className='col col-md-5.5 ml-0'>
                  <VideoPlayer
                    url='https://youtu.be/smEHIU2pV3c'
                    description='Speech delivered by Sarah Tremblett on People with Lived Experience in roles of Leadership within Community Organizations at Exchange 2020 Conference, MUN.'
                  />
                  <br />
                  <VideoPlayer
                    url='https://youtu.be/XXyrDDg8NxI'
                    description='Speech delivered by Claire Riggs and Heather Bartlett on Exploring Harm Reduction Strategies for Cannabis at Exchange 2020 Conference, Memorial University, March 4, 2020.'
                  />
                  <br />
                  <VideoPlayer
                    url='https://youtu.be/a-k6yZnWwAM'
                    description='Speech delivered by Jordan Aslett on Supple Community Service Learning in a Rural and Remote Context at Exchange 2020 Conference, Memorial University, March 4, 2020.'
                  />

                  <br />
                  <VideoPlayer
                    url='https://youtu.be/zSPiml2Eu_g'
                    description='PANEL DISCUSSION on Building Research Capacity Through Public Engagement: Experiences at Memorial University and Beyond at Exchange 2020 Conference, Memorial University, March 4, 2020.'
                  />
                </div>
              </div>
            </div>
          </div>
          {/*---- End the body part -----*/}
        </div>
        {/*---- End the video section-----*/}
      </section>
    </>
  );
};

export default Videos;
