import React from "react";
import Button from "../button/Button";

const ModalContent = (props) => {
  return (
    <>
      <div>
        <h4>Modal Content</h4>
      </div>

      <Button btn_type='Danger' clicked={props.modalClosed}>
        Cancel
      </Button>
      <Button btn_type='Success' clicked={props.modalContinue}>
        Continue
      </Button>

      {/* <button
        type='button'
        className='btn btn-danger'
        onClick={props.modalClosed}
      >
        CANCEL
      </button>
      <button
        type='button'
        className='btn btn-success'
        onClick={props.modalContinue}
      >
        CONTINUE
      </button> */}
    </>
  );
};

export default ModalContent;
