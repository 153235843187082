import React from "react";
import { useState } from "react";
// import { Button } from "react-bootstrap";
//import "./cardUI-style.css";
// import { NavLink } from "react-router-dom";

const CardUI = (props) => {
  const [show, setShow] = useState(false);
  return (
    <div>
      <div className='card text-center shadow'>
        {props.imgsrc && (
          <div className='overflow'>
            <img src={props.imgsrc} alt='Image1' className='card-img-top' />
          </div>
        )}

        <div className='card-body text-dark'>
          <h5 className='card-title'> {props.title}</h5>
          <h6 className='card-text text-secondary'>
            {" "}
            Instructor: {props.instructor}
          </h6>
          {/* /************************ */}

          <p className='card-text text-secondary text-justify'>
            <strong>Description : </strong>
            {props.description}
          </p>

          {/* /************************ */}

          {show && (
            <div>
              <p className='card-text text-secondary text-justify'>
                <strong> Goals : </strong>
                {props.goals}
              </p>
              <p className='card-text text-secondary text-justify'>
                <strong>Learning Objectives/Outcomes : </strong>
                {props.outcomes}
              </p>
              <p className='card-text text-secondary text-justify'>
                <strong>Instructional Method : </strong>
                {props.outcomes}
              </p>
            </div>
          )}
          {/* /************************ */}
          <button
            type='button'
            className='btn btn-outline-primary'
            onClick={() => setShow(!show)}
          >
            {show ? "Hide details" : "See details"}
          </button>
          {/* /************************ */}

          {/* <NavLink to={props.link_to} className='btn btn-outline-success'>
            {" "}
            See more
          </NavLink> */}
        </div>
      </div>
    </div>
  );
};

export default CardUI;
