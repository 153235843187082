import React from "react";
// import "./course-style.css";

import Sidebar from "../../components/common/Sidebar";
import Breadcrumb from "../../components/common/Breadcrumb";
// import Section from "../../components/common/Section";
// import CardUI from "../../components/common/CardUI";
import CourseCardUI from "../../components/common/CourseCardUI";
import CardUICourse from "../../components/card/CardUICourse";

import Layout from "../layout/Layout";

// import img1 from "../../assets/images/kt_04_small.png";
//import SearchForm from "./SearchForm";

export default function CoursePage() {
  // let headerSection = [
  //   <Breadcrumb name='Community Service Learning (CLS) Courses' />,
  // ];
  // let sidebar = [<Sidebar />];
  // let bodySections = [
  //   // <Section
  //   //   title='CLS Courses'
  //   //   body={"Sample description of the projects..."}
  //   // />,
  // ];

  return (
    <>
      {/* {headerSection} */}
      <Breadcrumb name='Community Service Learning (CLS) Courses' />
      <Layout>
        {/* {bodySections} */}
        <CardUICourse
          imgsrc={""}
          title='Community Service Learning Assignments for Course on Community Development'
          link_to='/courses'
          instructor='Dr. Stephen Ellenbogen'
          title_p1='Model with two student roles:'
          description_p1='Working in teams, you will designate one team member to be the contact person. This person will contact a community-based organization (CBO) about engaging in a mutually beneficial relationship. In this relationship, your team will arrange an interview with a staff member of the community agency to learn about their organizational philosophy, goals, strengths, challenges, etc. In return, your team will do a research literature review on a topic chosen by the agency, present your findings orally, and submit to them a written report. Each student occupies one of two roles (researching, interviewing) and supports other members of the team.'
          title_p2='Model with three student roles:'
          description_p2='Assemble in teams. Your team will contact a community agency to engage in a mutually beneficial relationship. In this relationship, your team will arrange an interview with a staff member of the community agency to learn about: their organizational philosophy, goals, strengths, challenges, etc. In return, your team will do a research literature review on a topic chosen by the agency, present your findings orally and submit to them a written report. Each student occupies one of three roles (coordinating, researching, interviewing) and supports other members of the team.'
          title_p3='Course assignment descriptions:'
          description_p3='For information: contact author(s).'
        />
        ,
        {/* <CourseCardUI
          imgsrc={""}
          title='Course for Social Justice '
          link_to='/courses'
          instructor='Dr. Stephen Ellenbogen'
          description='Community Leadership Development is an exciting two-year diploma program designed to develop leadership competencies for working effectively to reach targeted goals with individuals, groups and organizations within communities. Students engage in the process of learning designed to develop individual leadership capacity while exploring and researching social and economic situations in a community...'
          goals='As you begin encoding the syllabus, it is important to ask these questions: What are the big ideas that you are going to cover in your course? What are the essential understandings that students will take away with them after the course has finished? It is imperative to define these course professional goals, as they'
          outcomes='At the end of the day, it is pointless to have your students taken the course if they have not learned a single thing. Which means formulating a syllabus is easier said than done since you also have to start designing outcomes that the students might achieve during their time in the university, and it is a valuable element to have in your syllabus. '
          instructional_method='Though it is not always required, indicating on how the course is going be taught from here on is not really such a bad idea whether they would be lectures, seminars, video presentations, etc'
        /> */}
      </Layout>
    </>
  );
}
