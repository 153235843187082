import React, { useReducer } from "react";
import PaperContext from "./paperContext";
import paperReducer from "./paperReducer";

import Axios from "axios";
//import { Axios } from "../config";

const PaperProvider = (props) => {
  const initialState = {
    papers: [],
    currentPaper: null,
    loading: true,
  };

  const [state, dispatch] = useReducer(paperReducer, initialState);

  // Get all papers
  const getPapers = async () => {
    try {
      dispatch({ type: "SENDING_REQUEST" });
      const res = await Axios.get("/api/papers/get-papers");
      const data = await res.data;
      // console.log("data: ", res.data);
      dispatch({ type: "REQUEST_FINISHED" });
      dispatch({ type: "SET_PAPERS", payload: data });
    } catch (err) {
      console.log(err);
    }
  };

  // Get conference by id
  const getPaperById = async (id) => {
    try {
      dispatch({ type: "SENDING_REQUEST" });
      const res = await Axios.get(`/api/papers/get-conference/${id}`);
      const data = await res.data;
      dispatch({ type: "REQUEST_FINISHED" });
      dispatch({ type: "SET_PAPER", payload: data });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <PaperContext.Provider
      value={{
        papers: state.papers,
        currentPaper: state.currentPaper,
        loading: state.loading,
        getPapers: getPapers,
        getPaperById,
      }}
    >
      {props.children}
    </PaperContext.Provider>
  );
};

export default PaperProvider;
