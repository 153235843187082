import React from "react";
// import "./course-style.css";

import Sidebar from "../../components/common/Sidebar";
import Breadcrumb from "../../components/common/Breadcrumb";
import Layout from "../layout/Layout";
import "./outcomes-style.css";

export default function LayoutOutcomesPage() {
  let headerSection = [<Breadcrumb name='FAQ ' />];
  let sidebar = [<Sidebar />];
  // let bodySections = [
  //   <Section
  //     title='Faculty Reflection on CLS Outcomes '
  //     body='Sample description of the courses...'
  //   />,
  // ];

  return (
    <>
      {headerSection}

      <Layout>
        {/* {bodySections} */}
        <div>
          <div className='card text-center shadow'>
            <div className='card-body text-dark'>
              {/* <h5 className='card-title'> FAQ </h5> */}
              <h6 className='card-text text-secondary'>
                {/* Dr. Stephen Ellenbogen */}
              </h6>
              {/****************************/}
              <p className='card-text text-secondary text-justify'>
                {/* <strong>Description : </strong> */}
                <strong>
                  {" "}
                  Q1. What is "Supple" Community Service Learning?
                </strong>
                <br />
                <strong>Short answer: </strong>Through a two-course teaching
                strategy described in an earlier article by Ellenbogen (2017),
                students create a research summary on a topic of interest to a
                community organization in St. John’s, Newfoundland and Labrador,
                Canada.
                <br />
                <br />
                <strong>Long answer: </strong>
                The adjective is employed in the sense of making something that
                ‘easily fits’. That is, the focus is on devising a: sustainable
                course-based teaching strategy; that takes pragmatic approaches
                to service-learning; and seeks to “contribute pedagogic value to
                students, instructors, and community, without placing undue
                demands on these partners” (Ellenbogen, 2017, p. 318). In
                contrast, most service learning initiatives described in the
                literature rely on external funding and are unsustainable
                without continued support and institutional buy-in.
                <br />
                <br />
                The SCSL model used in this study aligns closely with service
                learning philosophies that seek to enhance professional
                preparedness, through activities that: encourage critical
                thinking; promote personal and interpersonal development; and
                require students to understand, synthesize, and apply knowledge
                (Eyler & Giles, 1999). The benefits are summarized in Table 1.
                <br />
                <br />
                The connection of the model to problem-based learning, learning
                through team projects, and pragmatism as a philosophy for
                guiding community work practice, democracy and mixed method
                inquiry, has been described elsewhere (Ellenbogen, 2017). The
                adaptability of pragmatism is worth noting. Such approaches
                integrate diverse perspectives (e.g., Indigenous, evidence-based
                perspective) and serve as a foundation for students and
                instructors to consider problems from different epistemological
                perspectives.
                <br />
                <br />
                The relationship of SCSL to self-organization also merits
                discussion. A simplistic explanation would be that the model
                seeks to encourage self-organizing skills, primarily in
                students, but in CBOs and educators as well. A more accurate
                depiction would be that SCSL seeks to harness self-organizing
                capacities that already exist, both in the classroom (where the
                model relies on students’ the capacities to organize projects
                and producing usable reports) and in CBOs (where the model
                relies on community workers’ capacities to identify topics that
                are worth exploring and, acting as key informants, contribute
                meaningful course content).
                <br />
                <br />
                Although the embedding of self-organizing strategies in SCSL was
                inspired by community work practice, systems theorist Donella
                Meadows (2008) elaborates on the importance of this concept in
                promoting resilient systems. To illustrate how systems which are
                conceived for short-term benefits tend to be fragile and easily
                break down, the author gave relevant examples, in such diverse
                field as biology and management. Conversely, systems with the
                ability to self-organize, that is to “learn, diversify,
                complexify, evolve” (p. 79), demonstrate resilience. They adjust
                to a changing world and are less susceptible to decaying forces.
                In our brief review of the literature, we found only one project
                that employed a dedicated self-organizing strategy (Parker et
                al., 2009).
                <br />
                <br />
                <h6>Table-1: Benefits of Supple Community Service Learning</h6>
                <table className='GeneratedTable'>
                  <thead>
                    <tr>
                      <th>Principal Recipient</th>
                      <th>Benefit </th>
                      <th>Examples and explanations</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Student </td>
                      <td>Self-organizing</td>
                      <td>
                        Building upon existing organizing capacity (e.g.,
                        managing project, working through problems as team)
                        leads to increased capacity for resilience, through
                        increased complexity according to Meadows (2008).
                      </td>
                    </tr>
                    <tr>
                      <td>Student CBO</td>
                      <td>Reciprocal knowledge gains</td>
                      <td>
                        CBO’s impart course-relevant knowledge in interviews and
                        through interaction with students’ research; students
                        use university resources to mobilize and impart research
                        and other knowledge.
                      </td>
                    </tr>
                    <tr>
                      <td>Student CBO</td>
                      <td>Reciprocity benefits</td>
                      <td>
                        Understanding and supporting each other’s needs conveys
                        inherent benefits.
                      </td>
                    </tr>
                    <tr>
                      <td>Student CBO</td>
                      <td>Research capacity</td>
                      <td>
                        Meets need to enhance research literacy in social
                        services, students, community
                      </td>
                    </tr>
                    <tr>
                      <td>CBO</td>
                      <td>Reduced Power Imbalance </td>
                      <td>
                        Bachelor’s students come across as young people who are
                        still learning, rather than authorities of learning
                        institution.
                      </td>
                    </tr>
                    <tr>
                      <td>Instructor</td>
                      <td>Reduced bureaucracy</td>
                      <td>
                        Community worker philosophy of drawing energy from
                        spontaneous action on ideas results in more feasible and
                        flexible teaching strategies.
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <br />
                <strong>References: </strong>
                Ellenbogen, S. (2017). An alternative model of community service
                learning: Students, community, and instructors learning from
                each other.{" "}
                <i>Higher Education, Skills and Work-Based Learning.</i>
                7(3), 315-330. <br />
                Eyler, J., & Giles, D. E. (1999). Where’s the learning in
                service-learning? San Francisco, CA: Jossey-Bass.
                <br /> Meadows, D (2008) Thinking in Systems: A Primer. White
                River Junction, Vermont: Chelsea Green <br />
                Parker, E. A., Myers, N., Higgins, H. C., Oddsson T., Price M.,
                & Gould, T. (2009). More than experiential learning or
                volunteering: a case study of community service learning within
                the Australian context. Higher Education Research & Development,
                28(6), 585-596, DOI: 10.1080/07294360903161147
              </p>
              <p className='card-text text-secondary text-justify'>
                <strong> Q2. Why is it useful in this context? </strong>
                <br />
                <strong>Short answer: </strong>Community-based organizations
                possess expertise in the delivery of accessible health and
                social services. However, they are often limited in their
                capacity to mobilize research knowledge. Universities possess
                expertise and resources for knowledge mobilization and are
                committed to serving their community.
              </p>

              {/* /************************ */}
              <p className='card-text text-secondary text-justify'>
                {/* <strong>Description : </strong> */}
                <strong>Q3. What is a Scale Up- Project (SUP)?</strong>
                <br />
                <strong>Short answer: </strong>Working with faculty and graduate
                student mentors and community partners, bachelor of social work
                students refine their community service learning course
                assignments and present them in community service oriented
                conferences (Exchange 2018 & 2020).
                <br />
                <br />
                <strong>Long answer:</strong> Bachelor of social work students
                complete community service learning course assignments as part
                of a course on community development. The assignment involves
                performing research summaries for the benefit of local
                community-based organization (CBO). SUPs are designed as
                extra-curricular activities that grow out of these initial
                partnerships. Students refine their summaries and present them
                in community service oriented conferences (Exchange 2018 &
                2020). SUPs are intended to strengthen the research and
                knowledge mobilization skills of students and community
                partners, to provide experience co-creating scholarly products
                that address a knowledge gap in the community. In the 2018
                iteration, a steering committee consisting of university and
                community members selected 6 assignments and invited the
                students and CBOs who co-created the work to participate as
                volunteers in a SUP. Working with their partnering CBOs, they
                refine their course assignments by adding research, deepening
                their reflections and editing. The summaries were presented at
                Exchange 2018.
              </p>
              <p className='card-text text-secondary text-justify'>
                <strong> Q4. What is a Long-term partnership?</strong>
                <br />
                The 2020 iteration also featured SUPs and conference
                presentations of community-university partnerships. However,
                project investigators identified four courses assignments with
                promising foundations for long-term partnerships (e.g.,
                competent student work, findings with clear implications for
                practice/policy or that raise questions for future research;
                interested partners). The first objectives were to identify and
                pursue mutually agreeable targets, e.g., the production of a
                manuscript for publication in a peer reviewed journal,
                application for research funding, transfer of knowledge toward
                an innovation in practice and policy. These partnerships
                necessitated close collaboration between mentors, students and
                community partners, and the development of processes that were
                adapted to best suit the assets and needs of each individual
                project. Some required prolonged engagement from the research
                team. Others required multiple faculty and graduate students and
                had to accommodate change, as some bachelor’s students could no
                longer commit to projects due to the increasing demands of their
                emerging social work careers.
              </p>
              {/* /************************ */}
              {/* <p className='card-text text-secondary text-justify'>
                <strong> For information: </strong>
                Contact us.
              </p> */}
            </div>
          </div>
        </div>
        ,
      </Layout>
    </>
  );
}
