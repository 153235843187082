import React from "react";
// import { Link } from "react-router-dom";

export default function Breadcrumb(props) {
  return (
    <>
      <section id='breadcrumb'>
        <div className='container mt-3'>
          <ol className='breadcrumb conference-breadcrumb'>
            <li className='ml-2'>{props.name}</li>
          </ol>
        </div>
      </section>
    </>
  );
}
