import React from "react";
import UserListing from "./UserListing";
import UserHome from "./UserHome";
import DashboardLayout from "../layout/DashboardLayout";

const UserPage = () => {
  return (
    <>
      <DashboardLayout page_name='Users'>
        <UserListing name='Users' />
        <hr />
        <UserHome />
      </DashboardLayout>
    </>
  );
};

export default UserPage;
