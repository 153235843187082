import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import AuthService from "../../services/authService";
import errorhandler from "../../services/errorHandler";

//import Users from "./Users";
//import { Link } from "react-router-dom";

export default function Sidebar() {
  const history = useHistory();

  const [authenticated, setAuthenticated] = useState(false);
  // const [authObj, setAuthObj] = useState({});

  useEffect(() => {
    AuthService.getAuthStatus()
      .then((res) => {
        setAuthenticated(res.data.auth_status || false);
        // setAuthObj(JSON.stringify(res.data, null, 2) || "No data received.");
      })
      .catch((err) => {
        errorhandler(err);
      });
  }, []);

  return (
    <>
      {/* Start ListGroup*/}

      <div className='list-group '>
        <Link to='/dashboard' className='list-group-item active main-color-bg'>
          <span className='fa fa-cog'></span>
          Dashboard
        </Link>

        <Link to='/dashboard-conferences' className='list-group-item'>
          <span className='fa fa-pencil fa-lg px-2'></span>
          Conferences
          {/* <span className='badge badge-pill badge-secondary float-right ml-3'>
            203
          </span> */}
        </Link>

        <Link to='/dashboard-events' className='list-group-item'>
          <span className='fa fa-pencil fa-lg px-2'></span>
          Events
          {/* <span className='badge badge-pill badge-secondary float-right ml-3'>
            203
          </span> */}
        </Link>
        <Link to='/users' className='list-group-item'>
          <span className='fa fa-user fa-lg px-2'></span>
          Users
          {/* <span className='badge badge-pill badge-secondary float-right ml-3'>
            203
          </span> */}
        </Link>

        <Link to='/profile' className='list-group-item '>
          <span className='fa fa-newspaper-o fa-lg px-2'></span>
          Profile
          {/* <span className='badge badge-pill badge-secondary float-right ml-3 '>
            121
          </span> */}
        </Link>
      </div>

      {/* End ListGroup*/}

      {/* ........................... */}
      {authenticated && (
        <button
          type='button'
          className='btn btn-outline-warning btn-block'
          onClick={(e) => {
            AuthService.logout();
            history.push("/login");
            //window.location.href = "/dashboard";
          }}
        >
          Login out
        </button>
      )}

      {/* Start Disk Space and Band-width*/}
      <div className='well mt-5'>
        <h6>Disk Space Used</h6>
        <div className='progress my-3'>
          <div className='progress-bar bg-success' style={{ width: "70%" }}>
            70%
          </div>
        </div>

        <h6>Bandwidth Used</h6>
        <div className='progress my-3'>
          <div className='progress-bar bg-success' style={{ width: "50%" }}>
            50%
          </div>
        </div>
      </div>
      {/* End Disk Space and Band-width*/}
    </>
  );
}
