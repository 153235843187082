import React from "react";
import ReactPlayer from "react-player";

function VideoPlayer(props) {
  return (
    <div>
      <ReactPlayer width='100%' height='350px' controls url={props.url} />
      <p>{props.description}</p>
    </div>
  );
}

export default VideoPlayer;
