import React from "react";
import { UserHeading } from "./UserHeading";
import UserList from "./UserList";
// import AddUser from "./AddUser";

const UserHome = () => {
  return (
    <>
      <div className='col-xs-1 text-center'>
        {/* <strong> useContext + useReducer</strong> */}
        <UserHeading />
        <br />
        {/* ?    <AddUser /> */}
        <UserList />
        <br />
      </div>
    </>
  );
};

export default UserHome;
