import React from "react";

export default function ErrorPage() {
  return (
    <>
      <section>
        <div className='container'>
          <h3 className='text-center text-danger'>Error: 404 PAGE NOT FOUND</h3>
        </div>
      </section>
    </>
  );
}
