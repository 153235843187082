import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../context/StateProvider";
//import {  useGlobalState } from "../context/StateProvider";
import { Link, useHistory } from "react-router-dom";

export default function EditUser(props) {
  const { editUser, users } = useContext(GlobalContext);
  //const { editUser, users } = useGlobalState();
  //console.log('Total users [edit page]: ' + users.length)

  const [selectedUser, setSelectedUser] = useState({
    id: "",
    first_name: "",
    last_name: "",
    email: "",
  });
  const history = useHistory();
  const currentUserId = props.match.params.id;

  useEffect(() => {
    const userId = currentUserId;
    const selectedUser = users.find((user) => user.id === userId);
    // console.log("userId = " + users.length  + '  selectedUser = '+ selectedUser.name)
    setSelectedUser(selectedUser);
  }, [currentUserId, users]);

  const onChange = (e) => {
    setSelectedUser({ ...selectedUser, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    editUser(selectedUser);
    history.push("/users");
  };

  return (
    <div className='container text-center'>
      <div className='row'>
        <div className='col-md-6 offset-md-3'>
          <strong>Edit User Information</strong>
          <form onSubmit={onSubmit}>
            {/* <div className='form-group text-left'>
          <label htmlFor='user-name'>Name</label>
          <input
            type='text'
            // value={selectedUser.name}
            defaultValue={selectedUser.name}
            name='name'
            onChange={onChange}
            className='form-control'
            placeholder='Enter first name'
            required
          ></input>
        </div> */}

            <div className='form-group text-left'>
              <label htmlFor='user-first-name'>First Name</label>
              <input
                type='text'
                // value={selectedUser.first_name}
                defaultValue={selectedUser.first_name}
                name='first_name'
                onChange={onChange}
                className='form-control'
                placeholder='Enter first name'
                required
              ></input>
            </div>

            <div className='form-group text-left'>
              <label htmlFor='user-last-name'>Last Name</label>
              <input
                type='text'
                // value={selectedUser.last_name}
                defaultValue={selectedUser.last_name}
                name='last_name'
                onChange={onChange}
                className='form-control'
                placeholder='Enter last name'
                required
              ></input>
            </div>

            <div className='form-group text-left'>
              <label htmlFor='user-email'>Email Address</label>
              <input
                type='email'
                defaultValue={selectedUser.email}
                name='email'
                onChange={onChange}
                className='form-control'
                placeholder='Enter last name'
                required
              ></input>
            </div>

            <div className='form-group text-left'>
              <label htmlFor='user-password'>Password</label>
              <input
                type='password'
                defaultValue={selectedUser.password}
                name='password'
                onChange={onChange}
                className='form-control'
                placeholder='Enter password'
                required
              ></input>
            </div>

            <button className='btn btn-success' type='submit'>
              Edit Name
            </button>
            <Link to='/users' className='btn btn-danger ml-2'>
              Cancel
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
}
