// import React, { createContext, useReducer, useContext } from 'react';
import React, {createContext, useContext, useReducer} from 'react';

import EventReducer from './EventReducer';

// Initial State
const initialState = {
  events:[]
}

// Create Context
export const EventContext = createContext(initialState);

// Provider Component
export const EventProvider = ({ children }) => {
  const [state, dispatch] = useReducer(EventReducer, initialState);

  // Actions
  const addEvent = (event) => {
    dispatch({
      type: 'ADD_EVENT',
      payload: event
    })
  }

  const removeEvent = (id) => {
    dispatch({
      type: 'REMOVE_EVENT',
      payload: id
    })
  }

  const editEvent = (event) => {
    dispatch({
      type: 'EDIT_EVENT',
      payload: event
    })
  }

  return (
    <EventContext.Provider value={{
      events: state.events,
      removeEvent,
      addEvent,
      editEvent
    }}>
      {children}
    </EventContext.Provider>
  )
}

// pull information from the data layer
export const useEventState = () => useContext(EventContext);



// import React, {createContext, useContext, useReducer} from "React";
// import AppReducer from './AppReducer';


// // Initial State
// const initialState = {
//     events: []
//   }

// // Create Context -  prepare the data layer
// export const StateContext = createContext(initialState);

// // Provider Component - wrap our app and provide the data layer
// export const GlobalProvider = ({ children }) => {
//   const [state, dispatch] = useReducer(AppReducer, initialState);

//   // Actions
//   const deleteEvent = (id) => {
//     dispatch({
//       type: 'DELETE_EVENT',
//       payload: id
//     })
//   }

//   const addEvent = (event) => {
//     dispatch({
//       type: 'ADD_EVENT',
//       payload: event
//     })
//   }

//   const editEvent = (event) => {
//     dispatch({
//       type: 'EDIT_EVENT',
//       payload: event
//     })
//   }

//   return (
//     <StateContext.Provider value={{
//       events: state.events,
//       deleteEvent,
//       addEvent,
//       editEvent
//     }}>
//       {children}
//     </StateContext.Provider>
//   )
// }