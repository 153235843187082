// import React, { createContext, useReducer, useContext } from 'react';
import React, { createContext, useContext, useReducer } from "react";

import userReducer from "./userReducer";

// Initial State
const initialState = {
  users: [],
};

// Create Context
export const GlobalContext = createContext(initialState);

// Provider Component
export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);

  // Actions
  const addUser = (user) => {
    dispatch({
      type: "ADD_USER",
      payload: user,
    });
  };

  const removeUser = (id) => {
    dispatch({
      type: "REMOVE_USER",
      payload: id,
    });
  };

  const editUser = (user) => {
    dispatch({
      type: "EDIT_USER",
      payload: user,
    });
  };

  return (
    <GlobalContext.Provider
      value={{
        users: state.users,
        removeUser,
        addUser,
        editUser,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

// pull information from the data layer
export const useGlobalState = () => useContext(GlobalContext);

// import React, {createContext, useContext, useReducer} from "React";
// import AppReducer from './AppReducer';

// // Initial State
// const initialState = {
//     events: []
//   }

// // Create Context -  prepare the data layer
// export const StateContext = createContext(initialState);

// // Provider Component - wrap our app and provide the data layer
// export const GlobalProvider = ({ children }) => {
//   const [state, dispatch] = useReducer(AppReducer, initialState);

//   // Actions
//   const deleteEvent = (id) => {
//     dispatch({
//       type: 'DELETE_EVENT',
//       payload: id
//     })
//   }

//   const addEvent = (event) => {
//     dispatch({
//       type: 'ADD_EVENT',
//       payload: event
//     })
//   }

//   const editEvent = (event) => {
//     dispatch({
//       type: 'EDIT_EVENT',
//       payload: event
//     })
//   }

//   return (
//     <StateContext.Provider value={{
//       events: state.events,
//       deleteEvent,
//       addEvent,
//       editEvent
//     }}>
//       {children}
//     </StateContext.Provider>
//   )
// }
