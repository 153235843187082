import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { getCurrentUser } from "../../services/userService";
import capitalize from "../../services/capitalize";
import errorHandler from "../../services/errorHandler";
import {
  getConferences,
  getPresentationTypes,
} from "../../services/conferenceService";
import Axios from "axios";
import DashboardLayout from "../layout/DashboardLayout";
import { useGlobalState } from "../../context/StateProvider";
import FileUpload from "../uploadOld/FileUpload";

const ProfileUpdate = (props) => {
  const history = useHistory();
  const currentUserId = props.match.params.id;
  const user = useState([]); // Need to change

  const [selectedUser, setSelectedUser] = useState({
    id: -1,
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    errors: {
      first_name: "",
      last_name: "",
      email: "",
    },
  });

  const handleChange = (e) => {
    //  isEmpty(e);
    setSelectedUser({ ...selectedUser, [e.target.name]: e.target.value });
    console.log(("SelectedUser:", selectedUser));
  };

  const [satisfy_length, setSatisfyLength] = useState(true);
  const [message, setMessage] = useState("");
  const [completed, setCompleted] = useState(false);

  // const { addUser } = useContext(GlobalContext);
  const { users, addUser } = useGlobalState();

  console.log("user_id", currentUserId);

  useEffect(() => {
    const { id, first_name, last_name, email } = getCurrentUser();
    setSelectedUser({ id, first_name, last_name, email });
  }, []);

  // useEffect(() => {
  //   const userId = currentUserId;
  //   // const selectedUser = users.find((user) => user.id === userId);
  //   setSelectedUser(selectedUser);
  // }, [currentUserId, users]);

  console.log("selectedUser", selectedUser);
  console.log("currentUserId", selectedUser);

  // -------------------//
  // const clearInputFields = () => {
  //   setFirstName("");
  //   setLastName("");
  //   setEmail("");
  //   setPassword("");
  //   setConfirmPassword("");
  // };

  // Register the user by POST Method//
  const registerUser = async (new_user) => {
    await Axios.post("/api/auth/register", new_user)
      .then((res) => {
        // Registration successful
        if (res.data.reg_status) {
          setMessage(res.data.message);
          setCompleted(true);
          // alert("MSG: " + res.data.message);

          // Add user's some information to the store
          let dt = new Date().toISOString();
          const { first_name, last_name, email } = new_user;
          const new_user_some_info = {
            // id: uuid(),
            first_name,
            last_name,
            email,
            created_on: dt,
          };
          addUser(new_user_some_info);

          // Clear the input fields - since  the user successfully registered.
          // clearInputFields();
          history.push("/register");
        } else {
          // Input validation error message form Backend
          if (res.data.errors) {
            setMessage(res.data.errors[0].msg);
          } else {
            setMessage(res.data.message); // Other msg from server
          }
        }
      })
      .catch((err) => {
        alert("Submission not successful ! Error: " + err);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let new_user = {
      // id: uuid(),
      first_name: selectedUser.first_name,
      last_name: selectedUser.last_name,
      email: selectedUser.email,
      password: selectedUser.password,
    };
    if (selectedUser.password.length < 6) {
      setSatisfyLength(false);
      // setMessage("Please enter a password with 6 or more characters.");
    } else {
      // Register the user by POST method//
      registerUser(new_user);
    }
  };

  // const onChangeFirstNameHandler = (e) => {
  //   setFirstName(e.target.value);
  //   setCompleted(false);
  //   setMessage("");
  // };

  // const onChangeLastNameHandler = (e) => {
  //   setLastName(e.target.value);
  //   setCompleted(false);
  //   setMessage("");
  // };

  // const onChangeEmailHandler = (e) => {
  //   setEmail(e.target.value);
  //   setCompleted(false);
  //   setMessage("");
  // };

  // const onChangePasswordHandler = (e) => {
  //   setPassword(e.target.value);
  //   setSatisfyLength(true);
  //   setPasswordMatch(true);
  //   setCompleted(false);
  //   setMessage("");
  // };

  // const onChangeConfirmPasswordHandler = (e) => {
  //   setConfirmPassword(e.target.value);
  //   setPasswordMatch(true);
  //   setCompleted(false);
  //   setMessage("");
  // };

  return (
    <>
      <DashboardLayout page_name='Profile Update'>
        <div className='card mb-3 mt-0'>
          <div className='card-header text-center'>Profile Update</div>

          <div className='container mb-3'>
            <div className='text-center mt-3'>
              {/* <h5>
              <strong> Add User</strong>
            </h5> */}
              {message ? (
                <p className={completed ? "text-success" : "text-danger"}>
                  {" "}
                  {message}
                </p>
              ) : null}
            </div>
            <div className='row'>
              <div className='col-md-8 offset-md-2'>
                <form className='well well-lg' onSubmit={onSubmit}>
                  <div className='form-group text-left'>
                    <label> First Name: </label>
                    <input
                      className='form-control'
                      type='text'
                      value={selectedUser.first_name}
                      name='first_name'
                      onChange={handleChange}
                      placeholder='Stephen'
                      required
                    ></input>
                  </div>

                  <div className='form-group text-left'>
                    <label> Last Name: </label>
                    <input
                      className='form-control'
                      type='text'
                      value={selectedUser.last_name}
                      name='last_name'
                      onChange={handleChange}
                      placeholder='Ellenbogen'
                      required
                    ></input>
                  </div>

                  <div className='form-group text-left'>
                    <label> Email Address: </label>
                    <input
                      className='form-control'
                      type='email'
                      value={selectedUser.email}
                      name='email'
                      onChange={handleChange}
                      placeholder='example@email.com'
                      required
                    ></input>
                  </div>

                  <div className='form-group text-left'>
                    <label>Password:</label>
                    <input
                      className='form-control'
                      type='password'
                      value={selectedUser.password}
                      onChange={handleChange}
                      // pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}'
                      placeholder='Password'
                      autoComplete='new-password'
                      required
                    />
                  </div>

                  <FileUpload />

                  {!satisfy_length && (
                    <p className='text-danger'>
                      {"Please enter a password with 6 or more characters!"}
                    </p>
                  )}

                  <button className='btn btn-success' type='submit'>
                    Submit
                  </button>
                  <Link to='/users' className='btn btn-danger ml-2'>
                    Cancel
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default ProfileUpdate;
