import React from "react";
import { Route, Switch } from "react-router-dom";

/***** Route for Clint/Public *****/
import Home from "../containers/home/HomePage";
import About from "../containers/about/AboutPage";
import Conference from "../containers/conference/ConferencePage";
import Events from "../containers/events/EventPage";
import Resources from "../containers/resources/ResourcesPage";
import Courses from "../containers/courses/CoursePage";
import Projects from "../containers/projects/ProjectPage";
import Outcomes from "../containers/outcomes/OutcomesPage";
import ErrorPage from "../containers/error/ErrorPage";
import EventDetails from "../containers/events/EventDetails";
import Contact from "../containers/contact/Contact";
import Admin from "../dashboard/admin/AdminPage";

/***** Route for Dashboard *****/
import Dashboard from "../dashboard/Dashboard";
import DashboardUsers from "../dashboard/users/UserPage";
import DashboardConferences from "../dashboard/conference/ConferencePage";
import DashboardEvents from "../dashboard/events/EventPage";

import Login from "../dashboard/admin/Login";
import Register from "../dashboard/admin/Register";

import AddConference from "../dashboard/conference/AddConference";
import AddPaper from "../dashboard/conference/AddPaper";

import AddEvent from "../dashboard/events/AddEvent";
import EditEvent from "../dashboard/events/EditEvent";

import AddUser from "../dashboard/users/AddUser";
import Profile from "../dashboard/users/Profile";
import ProfileUpdate from "../dashboard/users/ProfileUpdate";

import EditUser from "../dashboard/users/EditUser";

// import ImageUpload from "../dashboard/upload/ImageUpload";
import ImageUpload from "../dashboard/uploads/ImageUpload";

const Routes = () => {
  return (
    <>
      <Switch>
        <Route path='/' exact component={Home}></Route>
        <Route path='/about' component={About}></Route>
        <Route path='/conference' component={Conference}></Route>
        <Route path='/courses' component={Courses}></Route>
        <Route path='/projects' component={Projects}></Route>
        <Route path='/outcomes' component={Outcomes}></Route>
        <Route path='/resources' component={Resources}></Route>
        <Route path='/contact' component={Contact}></Route>
        <Route path='/events' component={Events}></Route>
        <Route path='/event-details' component={EventDetails}></Route>
        <Route path='/admin' component={Admin}></Route>
        {/***** Route for Dash board *****/}
        <Route path='/dashboard' component={Dashboard}></Route>
        <Route path='/dashboard-conferences' component={DashboardConferences} />
        <Route path='/add-conference' component={AddConference} />
        <Route path='/add-paper' component={AddPaper} />
        <Route path='/dashboard-events' component={DashboardEvents} />
        <Route path='/add-event' component={AddEvent} />
        <Route path='/edit-event/:id' component={EditEvent} />
        <Route path='/users' component={DashboardUsers} />
        <Route path='/add-user' component={AddUser} />
        <Route path='/edit-user/:id' component={EditUser} />

        <Route path='/image-upload' component={ImageUpload} />

        <Route path='/login' component={Login} />
        <Route path='/register' component={Register} />
        <Route path='/profile' component={Profile} />
        <Route path='/profile-update/:id' component={ProfileUpdate} />

        <Route path='*' component={ErrorPage} />
      </Switch>
    </>
  );
};

// const Routes = [
//   {
//     path: "/",
//     component: Home,
//     title: "Home",
//   },
//   {
//     path: "/conference",
//     component: Conference,
//     title: "Conference",
//   },
//   {
//     path: "/courses",
//     component: Courses,
//     title: "Courses",
//   },
//   {
//     path: "/resources",
//     component: Resources,
//     title: "Resources",
//   },
//   {
//     path: "/contact",
//     component: Contact,
//     title: "Contact",
//   },
//   {
//     path: "/admin",
//     component: admin,
//     title: "admin",
//   },
//   {
//     path: "/",
//     component: Home,
//     title: "Home",
//   },
//   {
//     path: "/",
//     component: Home,
//     title: "Home",
//   },
//   {
//     path: "/dashboard",
//     component: Dashboard,
//     title: "Dashboard",
//   },
//   {
//     path: "/dashboard/users",
//     component: UsersComponent,
//     title: "Users",
//   },
//   {
//     path: "/auth/register",
//     component: RegisterComponent,
//     title: "Register",
//   },
//   {
//     path: "/auth/login",
//     component: LoginComponent,
//     title: "Login",
//   },
// ];

export default Routes;
