import React, { useState, useEffect } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import { Link } from "react-router-dom";
import { getCurrentUser } from "../../services/userService";
import avatar from "../../assets/images/avatar-male.png";
// import errorHandler from "../../services/errorHandler";
//import Button from "../../components/UI/button/Button";

const Profile = (props) => {
  const [loading, setLoading] = useState(false);
  // const [conferences, setConferences] = useState([]);
  const [user, setUser] = useState([]);

  //*** GET Conferences ****** */
  useEffect(() => {
    setLoading(true);
    const user_info = getCurrentUser();
    setUser(user_info);
    // getCurrentUser()
    //   .then((res) => {
    //     setConferences(res.data);
    //     console.log("Conference:", res.data);
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     errorHandler(err);
    //   });
  }, []);
  console.log("user:", user);

  //*** DELETE ****** */
  //   const deleteContent = (id) => {
  //     deleteConference(id)
  //       .then(() => {
  //         alert("Successfully deleted the item " + id);
  //         delete conferences.id;
  //         setConferences(conferences);
  //       })
  //       .catch((err) => errorHandler(err));
  //     return;
  // };

  return (
    <>
      <DashboardLayout page_name='Profile'>
        {/* <div className='card mb-3 mt-0'>
          <div className='container mt-4'>
            <div className='card-title new-card-title text-center'>
              <h5 className='display-6 text-center mb-4'>Profile</h5>
            </div>
            <div>
              <p> User Profile Detail</p>
            </div>
          </div>
        </div> */}
        {/* -------------- */}
        <div className='card mb-3 mt-0'>
          <div className='container mt-4'>
            <div className='row'>
              <div className='col-4'>
                <div className='card'>
                  <div className='avatar-ctn'>
                    <img
                      src={avatar}
                      className='avatar'
                      alt='user profile picture'
                      style={{ width: 180, height: 200 }}
                    />
                  </div>
                </div>
                <div className='card-body'>
                  <h5 className='cart-title'>
                    {" "}
                    {user.first_name + " " + user.last_name}
                  </h5>
                  <a href='btn btn-primary'> {user.email}</a>
                  <br />
                  {/* <button
                    type='button'
                    className='btn btn-outline-info  btn-block'
                  ></button> */}
                  <Link
                    to={`/profile-update/${user.id}`}
                    className='btn btn-outline-info  btn-block'
                  >
                    Profile Update
                  </Link>
                </div>
              </div>
              <div className='col-8'>
                <div className='card text-center'>
                  <div className='card-header'>Profile Page</div>
                  <div className='card-body'>
                    <h5 className='card-title'>
                      {" "}
                      Hi - {user.first_name} welcome back!
                    </h5>
                    <p className='card-text'>This is your profile page.</p>
                    {/* <a href='#' className='btn btn-primary'>
                      Go some where
                    </a> */}
                  </div>
                  {/* <div className='card-footer text-muted'> Footer</div> */}
                </div>
              </div>
            </div>
            {/* <div className='card-footer text-muted'>Footer</div> */}
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default Profile;
