export default (state, action) => {
  switch (action.type) {
    case "SET_PAPERS":
      return {
        ...state,
        papers: action.payload,
      };
    case "SET_PAPER":
      return {
        ...state,
        currentPaper: action.payload,
      };
    case "SENDING_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "REQUEST_FINISHED":
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
