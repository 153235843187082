import React from "react";

import MainHeader from "../common/MainHeader";
import Breadcrumb from "../common/BreadcrumbDashboard";
import Sidebar from "../common/Sidebar";
import "./dashboard-style.css";

export default function DashboardLayout(props) {
  return (
    <>
      <MainHeader />
      <Breadcrumb
        page_name={props.page_name}
        inner_page_name={props.inner_page_name}
      />

      {/*Main section of the dashboard*/}
      <section id='dashboard-main'>
        <div className='container'>
          <div className='row'>
            {/*start left-side bar*/}
            <div className='col-md-3 pl-0'>
              <Sidebar />
            </div>

            {/****** Start the 2nd or Main column******/}
            <div className='col col-md-9 pr-0'>{props.children}</div>
          </div>
        </div>
      </section>
    </>
  );
}
