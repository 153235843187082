import React from "react";
// import Message from "./Message";
import Progress from "./Progress";

export default function FileUploader({
  handleFileInput,
  uploadPercentage,
  message,
  filename,
}) {
  return (
    <div className='file-uploader'>
      {/* {message ? <Message msg={message} /> : null} */}
      <div className='custom-file mb-4'>
        <input
          type='file'
          className='custom-file-input'
          id='customFile'
          onChange={handleFileInput}
        />
        <label className='custom-file-label' htmlFor='custom-file'>
          {filename}
        </label>
      </div>
      {uploadPercentage > 0 && <Progress percentage={uploadPercentage} />}
    </div>
  );
}

// export default function FileUploader(props) {
//   const fileInput = useRef(null);
//   return (
//     <div className='file-uploader'>
//       {props.message ? <Message msg={props.message} /> : null}
//       {/* <div>{props.children} </div> */}
//       <input type='file' onChange={props.handleFileInput} />
//       <Progress percentage={props.uploadPercentage} />

//       <button
//         onClick={(e) => fileInput.current && fileInput.current.click()}
//         // className='btn btn-primary'
//       />
//     </div>
//   );
// }

//-----------------
// import React, { useRef } from "react";

// export default function FileUploader(props) {
//   const fileInput = useRef(null);

//   return (
//     <div className='file-uploader'>
//       <div>{props.children} </div>
//       <input type='file' onChange={props.handleFileInput} />
//       <button
//         onClick={(e) => fileInput.current && fileInput.current.click()}
//         className='btn btn-primary'
//       />
//     </div>
//   );
// }
// ------------
// import React, { useState, useRef } from "react";

// export default function FileUploader({
//   onFileSelectSuccess,
//   onFileSelectError,
// }) {
//   const [file, onFileSelect] = useState("");
//   const fileInput = useRef(null);

//   const handleFileInput = (e) => {
//     // handle validations
//     onFileSelect(e.target.files[0]);
//     if (file.size > 1024)
//       onFileSelectError({ error: "File size cannot exceed more than 1MB" });
//     else onFileSelectSuccess(file);
//   };

//   return (
//     <div className='file-uploader'>
//       <input type='file' onChange={handleFileInput} />
//       <button
//         onClick={(e) => fileInput.current && fileInput.current.click()}
//         className='btn btn-primary'
//       />
//     </div>
//   );
// }
