import React from "react";
import ReactDOM from "react-dom";
// import reportWebVitals from "./reportWebVitals";

import ConferenceProvider from "./context/ConferenceProvider";
import PaperProvider from "./context/PaperProvider";

import { StateProvider } from "./context/StateProvider";
import { EventProvider } from "./context/EventProvider";

// import { StateProvider } from "./components/context/StateProvider";
// import reducer, {initialState } from "./components/context/Reducer";

/* The following line can be included in your src/index.js or App.js file*/
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";

//import App from "./routes/AppRouteTesting";
import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    {/* <StateProvider initialState={initialState} reducer={reducer}>
      <App />
    </StateProvider> */}
    <ConferenceProvider>
      <PaperProvider>
        <StateProvider>
          <EventProvider>
            <App />
          </EventProvider>
        </StateProvider>
      </PaperProvider>
    </ConferenceProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
