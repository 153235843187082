import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AdminHeader from "./AdminHeader";
import AuthService from "../../services/authService";
import errorhandler from "../../services/errorHandler";
import AdminLayout from "../layout/AdminLayout";

export default function AdminPage() {
  const history = useHistory();

  const [authenticated, setAuthenticated] = useState(false);
  // const [authObj, setAuthObj] = useState({});

  useEffect(() => {
    AuthService.getAuthStatus()
      .then((res) => {
        setAuthenticated(res.data.auth_status || false);
        // setAuthObj(JSON.stringify(res.data, null, 2) || "No data received.");
      })
      .catch((err) => {
        errorhandler(err);
      });
  }, []);

  // const logout = async () => {
  //   await Axios.post("/api/auth/logout", {
  //     headers: { "x-access-token": localStorage.getItem("token") },
  //   })
  //     .then((res) => {
  //       if (res.data.logout_status) {
  //         localStorage.removeItem("token");
  //         console.log("msg:", res.data.message);
  //       } else {
  //         console.log("msg-1:", res.data.message);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("msg-server-err:", err.message);
  //     });
  // };

  // const userAuthenticated = () => {
  //   // Axios.get("/api/auth/is-logged-in", {
  //   Axios.get("/api/auth/auth-status", {
  //     headers: { "x-access-token": localStorage.getItem("token") },
  //   })
  //     .then((res) => {
  //       if (res.data.auth_status) {
  //         // console.log("res.data.auth_status:", res.data.auth_status);
  //         setAuthenticated(true);
  //       } else {
  //         setAuthenticated(false);
  //         console.log("res.data.auth_status:", res.data.auth_status);
  //       }
  //     })
  //     .catch((err) => {
  //       // console.log("Err:", err);
  //     });
  // };

  // //Check the Session status
  // Axios.defaults.withCredentials = true;
  // useEffect(() => {
  //   userAuthenticated();
  // }, []);

  return (
    <>
      <AdminLayout title=''>
        <section id='main'>
          <div className='container mb-4'>
            <div className='row'>
              <div className='col-md-4 offset-md-4'>
                <form id='login' className='well well-lg'>
                  {/* ........................... */}
                  {!authenticated && (
                    <button
                      type='button'
                      className='btn btn-success btn-block'
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/login");
                        //window.location.href = "/dashboard";
                      }}
                    >
                      Login
                    </button>
                  )}

                  {/* ........................... */}
                  {authenticated && (
                    <button
                      type='button'
                      className='btn btn-outline-info btn-block'
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/dashboard");
                        //window.location.href = "/dashboard";
                      }}
                    >
                      Go to dashboard
                    </button>
                  )}

                  {/* ........................... */}

                  {authenticated && (
                    <button
                      type='button'
                      className='btn btn-info btn-block'
                      onClick={(e) => {
                        history.push("/profile");
                        //window.location.href = "/dashboard";
                      }}
                    >
                      User Profile
                    </button>
                  )}
                  {/* ........................... */}

                  {authenticated && (
                    <button
                      type='button'
                      className='btn btn-primary btn-block'
                      onClick={(e) => {
                        history.push("/register");
                        //window.location.href = "/dashboard";
                      }}
                    >
                      Add new user or admin
                    </button>
                  )}

                  {/* ........................... */}
                  {authenticated && (
                    <button
                      type='button'
                      className='btn btn-warning btn-block'
                      onClick={(e) => {
                        AuthService.logout();
                        history.push("/login");
                        //window.location.href = "/dashboard";
                      }}
                    >
                      Login out
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </section>
      </AdminLayout>
    </>
  );
}
