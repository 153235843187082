import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { getCurrentUser } from "../../services/userService";

export default function DashboardMainHeader() {
  const user = getCurrentUser();
  // console.log(user);
  return (
    <>
      <header id='header'>
        <div className='container my-0'>
          <div className='row'>
            <div className='col-md-10 my-3'>
              <h1>
                <span className='gear-fill'>
                  <span className='fa fa-cogs'></span>
                </span>
                Dashboard{" "}
                <small style={{ color: "red" }}> Manage Your Site </small>
              </h1>
            </div>
            {/* ..................... */}

            {/* ..................... */}
            <div className='col col-md-2 col-sm-4 py-3'>
              {user.first_name && <h6>Hi - {user.first_name}!</h6>}

              <Dropdown className='pull-right'>
                <Dropdown.Toggle variant='success' id='dropdown-basic'>
                  Create Content
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to='/add-conference'>
                    Add Conference
                  </Dropdown.Item>

                  <Dropdown.Item as={Link} to='/add-paper'>
                    Add Paper
                  </Dropdown.Item>

                  <Dropdown.Item as={Link} to='/add-event'>
                    Add Event
                  </Dropdown.Item>

                  <Dropdown.Item as={Link} to='/add-user'>
                    Add User
                  </Dropdown.Item>

                  <Dropdown.Item as={Link} to='/image-upload'>
                    Upload Image
                  </Dropdown.Item>

                  <Dropdown.Item href='#/action-1'>Action</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              {/* <DropdownButton id='dropdown-basic-button' title='Create Content'>
                <Dropdown.Item href='#/action-1'>Action</Dropdown.Item>
                <Dropdown.Item href='#/action-2'>Add Event</Dropdown.Item>
                <Dropdown.Item href='#/action-3'>Add User</Dropdown.Item>
              </DropdownButton> */}
            </div>
            {/* .....................
            <div className='col col-md-2'>
              <div className='dropdown create'>
                <button
                  className='btn btn-light dropdown-toggle dropdown-menu-right'
                  type='button'
                  id='dropdownMenuButton'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  Create Content
                  <span className='caret'></span>
                </button>
                <ul
                  className='dropdown-menu'
                  aria-labelledby='dashboardDropdownMenu'
                >
                  <li>
                    <Link
                      to='#'
                      type='button'
                      data-toggle='modal'
                      data-target='#addPage'
                    >
                      Add Page
                    </Link>
                  </li>
                  <li>
                    <Link to='#'>Add Post</Link>
                  </li>
                  <li>
                    <Link to='#'>Add Q&A</Link>
                  </li>
                  <li>
                    <Link to='/add-event'>Add Event</Link>
                  </li>
                  <li>
                    <Link to='#'>Add User</Link>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </header>
    </>
  );
}
