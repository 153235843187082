import React from "react";
import { useState } from "react";
import Axios from "axios";

import { Link, useHistory } from "react-router-dom";
import capitalize from "../../services/capitalize";
import errorHandler from "../../services/errorHandler";
// import { getPresentationTypes } from "../../services/conferenceService";

//import PagesMainSection from "../PagesMainSection";
// import CKEditorTest from "../CKEditorTest"; <CKEditorTest />

import DashboardLayout from "../layout/DashboardLayout";
import CardContent from "../common/CardContent";

const AddConference = () => {
  const [contentList, setContentList] = useState([]);
  const [showError, setShowError] = useState(true);

  const [conference, setConference] = useState({
    name: "",
    slogan: "",
    about: "",
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    venue: "",
    banner: "",
    catalog: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    about: "",
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    venue: "",
  });

  const history = useHistory();

  const handleChange = (e) => {
    isEmpty(e);
    setConference({ ...conference, [e.target.name]: e.target.value });
    console.log(("conference:", conference));
  };

  const isEmpty = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (!value) {
      let error_msg = `${capitalize(name)} field cannot be empty`;
      setErrors({ ...errors, [e.target.name]: error_msg });
    }
  };

  const handleValidation = (conference, errors) => {
    const { name, about, start_date, end_date, venue } = conference;
    let total_err = 0;
    if (!name) {
      errors.name = "Name is required.";
      total_err++;
      // setErrors({ ...errors, name: "Name is required" });
    }
    if (!about) {
      errors.about = "Conference description is required.";
      total_err++;
      // setErrors({ ...errors, about: "Conference description is required" });
    }
    if (!start_date) {
      errors.start_date = "Begin date is required.";
      total_err++;
      // setErrors({ ...errors, start_date: "Begin date is required"});
    }
    if (!end_date) {
      errors.end_date = "End date is required.";
      total_err++;
      // setErrors({ ...errors, end_date: "End date is required",});
    }
    if (!venue) {
      errors.venue = "Conference Venue is required.";
      total_err++;
      //setErrors({ ...errors, venue: "Conference Venue is required" });
    }
    console.log("Errors:", errors);
    return total_err;
  };

  //*** Sending data to Back-end by POST request *** */
  const submitContent = (e) => {
    const total_err = handleValidation(conference, errors);
    e.preventDefault();

    if (total_err === 0) {
      console.log("No input error(s) found");
      Axios.post("/api/conferences/add-conference", conference)()
        .then(() => {
          alert("Successfully inserted the event!");
        })
        .catch((err) => {
          errorHandler(err);
        });
      // console.log(conference.name);
      // setContentList([...contentList, conference]);
      // history.push("/dashboard-conferences");
    } else {
      console.log("Total " + total_err + " input error(s) found");
    }
  };

  return (
    <>
      <DashboardLayout page_name='Add Conferences'>
        <CardContent title='Add Conferences'>
          {/*---- Start FORM-----*/}
          <form>
            <div className='form-row'>
              <div className='form-group col-md-12'>
                <input
                  type='text'
                  className='form-control'
                  name='name'
                  value={conference.name}
                  placeholder='Conference Name*'
                  onChange={(e) => {
                    handleChange(e);
                    errors.name = "";
                  }}
                  required
                />
                {errors.name !== "" && (
                  <span style={{ color: "red" }}>{errors.name}</span>
                )}
              </div>

              <div className='form-group col-md-12'>
                <input
                  type='text'
                  className='form-control'
                  name='slogan'
                  value={conference.slogan}
                  onChange={handleChange}
                  placeholder='Conference Slogan'
                />
              </div>

              <div className='form-group col-md-12'>
                <textarea
                  rows='5'
                  name='about'
                  className='form-control'
                  placeholder='About Conference*'
                  // defaultValue='Sample description of an event.'
                  id='description'
                  value={conference.about}
                  onChange={(e) => {
                    handleChange(e);
                    errors.about = "";
                  }}
                  required
                />
                {errors.about !== "" && (
                  <span style={{ color: "red" }}>{errors.about}</span>
                )}
              </div>
              {/*******  Start Date *******/}

              <div className='form-group col-md-6'>
                <label htmlFor='start_date'>Start Date:</label>
                <input
                  type='date'
                  className='form-control'
                  name='start_date'
                  value={conference.start_date}
                  placeholder='Start date*'
                  onChange={(e) => {
                    handleChange(e);
                    errors.start_date = "";
                  }}
                />
                {errors.start_date !== "" && (
                  <span style={{ color: "red" }}>{errors.start_date}</span>
                )}
              </div>

              <div className='form-group col-md-6'>
                <label htmlFor='start_time'>Time:</label>
                <input
                  type='time'
                  className='form-control'
                  name='start_time'
                  value={conference.start_time}
                  onChange={handleChange}
                  placeholder='Start Time*'
                />
              </div>
              {/*******  End Date *******/}

              <div className='form-group col-md-6'>
                <label htmlFor='end_date'>End Date:</label>
                <input
                  type='date'
                  className='form-control'
                  name='end_date'
                  value={conference.end_date}
                  onChange={handleChange}
                  placeholder='End date*'
                />
              </div>

              <div className='form-group col-md-6'>
                <label htmlFor='end_time'>Time:</label>
                <input
                  type='time'
                  className='form-control'
                  name='end_time'
                  value={conference.end_time}
                  onChange={handleChange}
                  placeholder='End Time*'
                />
              </div>
              {/*******  Venue *******/}

              <div className='form-group col-md-12'>
                <input
                  type='text'
                  className='form-control'
                  name='venue'
                  value={conference.venue}
                  onChange={(e) => {
                    handleChange(e);
                    errors.venue = "";
                  }}
                  placeholder='Conference Venue*'
                />

                {errors.venue !== "" && (
                  <span style={{ color: "red" }}>{errors.venue}</span>
                )}
              </div>
              {/*******  Banner *******/}
              <div className='form-group col-md-7'>
                <input
                  type='text'
                  className='form-control'
                  name='banner'
                  value={conference.banner}
                  onChange={handleChange}
                  placeholder='Upload banner'
                />
              </div>

              <div className='form-group col-md-3'>
                <div className='input-group'>
                  <div className='input-group-prepend'>
                    <div className='input-group-text'>$</div>
                  </div>
                  <input
                    type='text'
                    name='price'
                    className='form-control'
                    id='price0'
                    placeholder='Price (optional)'
                  />
                </div>
              </div>

              <div className='form-group col-md-2'>
                <button
                  type='submit'
                  className='btn btn-primary'
                  onClick={submitContent}
                >
                  {/* <i
                            className='fa fa-arrow-circle-o-up'
                            aria-hidden='true'
                          ></i>{" "} */}
                  Upload
                </button>
              </div>

              {/*******  Catalog *******/}
              <div className='form-group col-md-7'>
                <input
                  type='text'
                  className='form-control'
                  name='catalog'
                  value={conference.catalog}
                  onChange={handleChange}
                  placeholder='Upload banner'
                />
              </div>

              <div className='form-group col-md-3'>
                <div className='input-group'>
                  <div className='input-group-prepend'>
                    <div className='input-group-text'>$</div>
                  </div>
                  <input
                    type='text'
                    name='price'
                    className='form-control'
                    id='price0'
                    placeholder='Price (optional)'
                  />
                </div>
              </div>

              <div className='form-group col-md-2'>
                <button
                  type='submit'
                  className='btn btn-primary'
                  onClick={submitContent}
                >
                  {/* <i
                            className='fa fa-arrow-circle-o-up'
                            aria-hidden='true'
                          ></i>{" "} */}
                  Upload
                </button>
              </div>
            </div>

            {/*******  Submit Button  *******/}
            <button
              type='submit'
              className='btn btn-info mt-3'
              onClick={submitContent}
            >
              Submit
            </button>
            <Link to='/dashboard' className='btn btn-danger ml-3 mt-3'>
              Cancel
            </Link>
          </form>
        </CardContent>
      </DashboardLayout>
    </>
  );
};

export default AddConference;
