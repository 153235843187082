import React from "react";
import { useContext, useEffect } from "react";

// import ShowPosts from "../../components/common/ShowPosts";
// import Overview from "../../components/common/Overview";
//import Breadcrumb from "../../components/common/Breadcrumb";
//import OverviewCardUI from "../../components/card/OverviewCardUI";

import Layout from "../layout/Layout";
// import ConferenceContext from "../../context/conferenceContext";
// import PaperContext from "../../context/paperContext";
import ShowPapers from "./ShowPapers";
import RepositoryOverviewUI from "../../components/card/RepositoryOverviewUI";
import { dataExchange2018 } from "./dataExchange2018";
import { dataExchange2020 } from "./dataExchange2020";
import { dataExchange2020poster } from "./dataExchange2020poster";

import "./conference-style.css";

export default function LayoutConferencePage() {
  // const { getConferences, conferences } = useContext(ConferenceContext);
  // const { getPapers, papers, loading } = useContext(PaperContext);
  // useEffect(async () => {
  //   getConferences();
  //   getPapers();
  // }, []);

  const exchange2018 = dataExchange2018;
  const exchange2020 = dataExchange2020;
  const exchange2020poster = dataExchange2020poster;

  console.log("papers", JSON.stringify(exchange2020));

  return (
    <>
      {/* {headerSection} */}
      {/* <Breadcrumb name='Conference' /> */}
      {/* sidebar and body section */}
      <br />
      <Layout>
        {/* <OverviewCardUI */}
        <RepositoryOverviewUI
          title={"Overview"}
          body={""}
          showBiggerTitle={true}
          key={"key-conf-1"}
        />

        <ShowPapers
          title='Oral Presentation 2020'
          papers={exchange2020}
          key={"OP-2020"}
        />

        <ShowPapers
          title='Poster Presentation 2020'
          papers={exchange2020poster}
          key={"OP-2020p"}
        />
        <ShowPapers
          title='Oral and Poster Presentation 2018'
          papers={exchange2018}
          key={"OP-2018"}
        />

        {/* <ShowPapers title='Poster Presentation' papers={papers} key={"PP-1"} /> */}
        {/* <ShowPosts key={"ConfShowPosts-1"} /> */}
      </Layout>
    </>
  );
}
