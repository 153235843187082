import React, { useReducer } from "react";
import ConferenceContext from "./conferenceContext";
import conferenceReducer from "./conferenceReducer";
import Axios from "axios";
//import { Axios } from "../config";

// const baseURL = "http://localhost:5000";

const ConferenceProvider = (props) => {
  const initialState = {
    conferences: [],
    currentConference: null,
    loading: true,
  };

  const [state, dispatch] = useReducer(conferenceReducer, initialState);

  // Get all conferences
  const getConferences = async () => {
    try {
      dispatch({ type: "SENDING_REQUEST" });
      const res = await Axios.get("/api/conferences/get-conferences");
      const data = await res.data;
      // console.log("data: ", res.data);
      dispatch({ type: "REQUEST_FINISHED" });
      dispatch({ type: "SET_CONFERENCES", payload: data });
    } catch (err) {
      console.log(err);
    }
  };

  // Get conference by id
  const getConferenceById = async (id) => {
    try {
      dispatch({ type: "SENDING_REQUEST" });
      //const res = await fetch(`/api/conferences/${id}`);
      const res = await Axios.get(`/api/conferences/get-conference/${id}`);
      const data = await res.data;
      dispatch({ type: "REQUEST_FINISHED" });
      dispatch({ type: "SET_CONFERENCE", payload: data });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ConferenceContext.Provider
      value={{
        conferences: state.conferences,
        currentConference: state.currentConference,
        loading: state.loading,
        getConferences: getConferences,
        getConferenceById,
      }}
    >
      {props.children}
    </ConferenceContext.Provider>
  );
};

export default ConferenceProvider;
