import React from "react";

import NavbarTop from "./NavbarTop";
import Navigation from "./Navigation";

export default function Header() {
  return (
    <>
      <header>
        {/* <NavbarTop />
        <br /> */}
        <Navigation />
      </header>
    </>
  );
}
