import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AdminHeader from "./AdminHeader";
import AuthService from "../../services/authService";
import errorhandler from "../../services/errorHandler";

import Axios from "axios";
//import { Axios } from "../../config";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);

  const [user, setUser] = useState({});

  const history = useHistory();

  // const login = async (user_credentials) => {
  //   await Axios.post("/api/auth/login", user_credentials)
  //     .then((res) => {
  //       console.log("res", res);
  //       if (res.status > 199 && res.status < 299) {
  //         if (res.data.auth_status) {
  //           setUser(res.data.user);
  //           setMessage(res.data.message);
  //           localStorage.setItem("token", res.data.token);
  //           localStorage.setItem("user", JSON.stringify(res.data));

  //           // console.log("Stringify:", JSON.stringify(res.data));
  //           console.log("Data.User:", res.data.user);
  //           history.push("/admin");
  //         } else {
  //           setMessage(res.data.message);
  //           // console.log("Username and password combination is wrong!");
  //         }
  //       } else {
  //         setMessage(res.data.message);
  //         console.log("User does not exist!");
  //       }
  //       console.log("MSG: ", message);
  //     })
  //     .catch((err) => {
  //       alert("Submission not successful ! Error: " + err);
  //     });
  // };

  // User login
  const onSubmitHandler = (e) => {
    e.preventDefault();
    let user_credentials = {
      email: email,
      password: password,
    };
    setLoading(true);
    // login(user_credentials);
    AuthService.login(user_credentials)
      .then((res) => {
        if (res.data.auth_status) {
          setUser(res.data.user);
          history.push("/admin");
        }
        setMessage(res.data.message);
        // setAuthenticated(data.auth_status || false);
      })
      .catch((err) => {
        errorhandler(err);
      });
    setLoading(false);
  };

  console.log("Usr:", user);

  // Check the Session status
  //Axios.defaults.withCredentials = true;
  // Check the authentication status using assigned-token
  useEffect(() => {
    AuthService.getAuthStatus()
      .then((res) => {
        setAuthenticated(res.data.auth_status || false);
        // setAuthObj(JSON.stringify(res.data, null, 2) || "No data received.");
        if (res.data.auth_status) {
          history.push("/admin");
        }
      })
      .catch((err) => {
        errorhandler(err);
      });
  }, [authenticated]);

  // // Check the authentication status using assigned-token
  // const userAuthenticated = () => {
  //   // Axios.get("/api/auth/is-logged-in", {
  //   Axios.get("/api/auth/auth-status", {
  //     headers: { "x-access-token": localStorage.getItem("token") },
  //   })
  //     .then((res) => {
  //       console.log("isLoggedIn: ", res);
  //       if (res.data.auth_status) {
  //         setAuthenticated(true);
  //       } else {
  //         setAuthenticated(false);
  //       }
  //     })
  //     .catch((err) => {
  //       // console.log("Err:", err);
  //     });
  // };
  //
  //   useEffect(() => {
  //     userAuthenticated();
  //     if (authenticated) {
  //       history.push("/admin");
  //     }
  //   }, [login_status]);

  //On Change Handler

  const onChangeEmailHandler = (e) => {
    setEmail(e.target.value);
    setMessage("");
  };

  const onChangePasswordHandler = (e) => {
    setPassword(e.target.value);
    setMessage("");
  };

  return (
    <>
      <AdminHeader title='Account Login' />
      <section id='main'>
        <div className='container mb-4'>
          <div className='row'>
            <div className='col-md-4 offset-md-4'>
              <div className='text-center mt-3'>
                {message ? <p className={"text-danger"}> {message}</p> : null}
                {/* {user && <p className={"text-success"}> {user}</p>} */}
              </div>
              <form
                id='login'
                className='well well-lg'
                onSubmit={onSubmitHandler}
              >
                <div className='form-group'>
                  <label>Email Address:</label>
                  <input
                    className='form-control'
                    type='email'
                    name='email'
                    value={email}
                    onChange={onChangeEmailHandler}
                    placeholder='Enter email'
                    autoComplete='username'
                    required
                  />
                </div>
                <div className='form-group'>
                  <label>Password: </label>
                  <input
                    className='form-control'
                    type='password'
                    name='password'
                    value={password}
                    onChange={onChangePasswordHandler}
                    placeholder='Enter password'
                    autoComplete='current-password'
                    required
                  />
                </div>
                <button type='submit' className='btn btn-success btn-block'>
                  {loading ? "loading..." : "Login"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
