const authHeader = () => {
  // const user_token = localStorage.getItem("token");
  // // console.log("userToken:", userToken);
  // if (user_token) {
  //   return { "x-access-token": user_token };
  // } else {
  //   return {};
  // }

  // OR...

  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.token) {
    return { "x-access-token": user.token };
  } else {
    return {};
  }
};

export default authHeader;
