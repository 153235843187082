import React from "react";
import bannerImg from "../../assets/images/banner1.png";

export default function Banner() {
  return (
    <div>
      <div
        style={{
          backgroundImage: "url(" + bannerImg + ")",
          backgroundSize: "cover",
          height: "40vh",
          color: "#f4f5f6",
        }}
      >
        {/* <div className='container'>
          <div className='row justify-content-start'>
            <div className='col-xs-12 col-sm-8 col-md-5 bg-custom d-none d-md-block py-3 px-3 my-4'>
              <h3>Community Research Exchange</h3>
            <div className='border-top border-primary w-50 mx-auto my-3'></div>
              <h3 className='pb-3'>News description goes here.</h3>
            </div>
          </div>
        </div> */}

        {/* <p> Banner...</p> */}
      </div>
    </div>
  );
}
