const getData = () => {
  let data = [
    {
      title: "Introduction to Microsoft Project",
      date: "Sep 12, 2020",
      time: "9:30 PM - 10:30PM",
      location: "Online",
      presenter: "Abu Naser Sarker",
      description:
        "Microsoft Project is a project that brings together community-based organizations, Bachelor of Social Work students, graduate students and leading scholars to exchange ideas on how community service learning models can be tailored to promote research capacity building and knowledge mobilization. The conference will showcase several ongoing collaborative research projects and provide a space for dialogue on how universities can engage in mutually beneficial partnerships with community based organizations.",
      event_link: "https://gazette.mun.ca/events/#",
    },
    {
      title: "Exchange 2020 conference",
      date: "Mar 04, 2020",
      time: "9:30 PM - 10:30PM",
      location: "Memorial University",
      presenter: "Abu Naser Sarker",
      description:
        "Exchange 2020 is a conference that brings together community-based organizations, Bachelor of Social Work students, graduate students and leading scholars to exchange ideas on how community service learning models can be tailored to promote research capacity building and knowledge mobilization. The conference will showcase several ongoing collaborative research projects and provide a space for dialogue on how universities can engage in mutually beneficial partnerships with community based organizations.",
      event_link: "https://gazette.mun.ca/events/#",
    },
    {
      title: "Exchange 2018 conference",
      date: "Mar 11, 2018",
      time: "9:30 PM - 10:30PM",
      location: "Memorial University",
      presenter: "Abu Naser Sarker",
      description:
        "Exchange 2018 is a conference that brings together community-based organizations, Bachelor of Social Work students, graduate students and leading scholars to exchange ideas on how community service learning models can be tailored to promote research capacity building and knowledge mobilization. The conference will showcase several ongoing collaborative research projects and provide a space for dialogue on how universities can engage in mutually beneficial partnerships with community based organizations.",
      event_link: "https://gazette.mun.ca/events/#",
    },
  ];
  return data;
};

export default getData;
