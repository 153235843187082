import React, { useState } from "react";
import "./home-style.css";
import { NavLink } from "react-router-dom";

export default function WebsiteOverview() {
  const [show, setShow] = useState(false);
  const paragraph1 = (
    <div style={{ textAlign: "justify", textJustify: "interWord" }}>
      The website is divided into the following sections:{" "}
      <ol id='menu'>
        <li>
          <strong>Objectives and goals</strong> of program.
        </li>
        <li>
          <strong>Repository</strong> of Exchange 2018 and 2020 conference
          presentations, each of which is the product of a scale-up project
        </li>
        <li>
          <strong>Examples</strong> of{" "}
          <NavLink to='/outcomes'> scale-up projects</NavLink> that became
          <NavLink to='/projects'> long-term partnership projects</NavLink>.
        </li>
        <li>
          <strong>Descriptions</strong> of courses that integrate community
          service learning and research knowledge mobilization assignments
        </li>
        <li>
          Scholarly papers and presentations on community service learning
        </li>
        <li> Other community service learning initiatives. </li>
      </ol>{" "}
      {/* (Contact us) (just a link to page with Program email and Faculty
      descriptions/emails */}
    </div>
  );
  return (
    <div>
      <p className='p-font-size'>{paragraph1}</p>
    </div>
  );
}
