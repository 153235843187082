import React from "react";
import ConferenceListing from "./ConferenceListing";
import DashboardLayout from "../layout/DashboardLayout";

const ConferencePage = () => {
  return (
    <>
      <DashboardLayout page_name='Conferences'>
        <ConferenceListing />
      </DashboardLayout>
    </>
  );
};

export default ConferencePage;
