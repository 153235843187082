import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import DisplayPaper from "./DisplayPaper";
// import { useEffect } from "react";
// import axios from "axios";
// import { Axios } from "../../config";

const Pagination = ({ listOfItems, postPerPage }) => {
  // const [currentPage, setCurrentPage] = useState(0);
  // const [itemsPerPage] = useState(postPerPage);
  const currentPage = 1;
  const setCurrentPage = 1;
  const itemsPerPage = postPerPage;
  // Get the current post
  // const indexOfFirstItem = currentPage * itemsPerPage;
  // const indexOfLastItem = indexOfFirstItem + itemsPerPage;
  // const currentItems = listOfItems.slice(indexOfFirstItem, indexOfLastItem);

  const currentItems = listOfItems;
  console.log("CurrentItems_New:", JSON.stringify(currentItems));

  const displayItems = currentItems.map((item, idx) => {
    return <DisplayPaper paper={item} key={idx} />;
  });

  const pageCount = Math.ceil(listOfItems.length / itemsPerPage);
  //Change the page
  const pageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <>
      {displayItems}
      <br />
      <ReactPaginate
        previousLabel='Prev'
        nextLabel='Next'
        pageCount={pageCount}
        onPageChange={pageChange}
        containerClassName='pagination-btn'
        previousLinkClassName='previous-btn'
        nextLinkClassName='next-btn'
        activeClassName='pagination-active'
        disabledClassName='pagination-disable'
      />
    </>
  );
};

export default Pagination;
