import React from "react";

const CardContent = (props) => {
  return (
    <>
      {/*Main section of the dashboard: col-md-9 Section*/}
      <div id='dashboard-main'>
        <div className='card mb-3 mt-0'>
          <div className='card-header main-color-bg '>
            <div className='card-title new-card-title'> {props.title}</div>
          </div>
          {/*---- Start Edit page body-----*/}
          <div className='card-body mx-4'>
            {props.children} {/*---- Start FORM-----*/}
          </div>
        </div>
      </div>
    </>
  );
};

export default CardContent;
