import React, { useState } from "react";

export default function CardUI(props) {
  return (
    <>
      <div>
        {/* <div className='card' style={{ boxShadow: " 0px 0px #ffffff" }}> */}
        <div className='card text-center shadow mb-2'>
          {/* /************************ */}
          {props.showBiggerTitle && (
            <div className='card-header conference-bg-color mb-0'>
              <div className='card-title new-card-title'>
                <b>{props.title} </b>
              </div>
            </div>
          )}

          {/* /************************ */}
          <div className='card-body text-dark'>
            {/*---- Start the Text-Style title -----*/}
            {!props.showBiggerTitle && (
              <div>
                <h5 className='card-title'> {props.title}</h5>
                <div className='border-top border-primary w-75 mx-auto my-3'></div>
              </div>
            )}
            {/*---- End the Text-Style title -----*/}

            {/* /**************Use one of the p tag ********** */}
            {/* <p className='text-justify px-4'> {props.body}</p> */}
            {props.body && <p className='text-justify px-4'> {props.body}</p>}
            {/* <p className='card-text text-secondary text-justify'>{props.body}</p> */}
            {/* <p className='card-text text-justify'>{props.body}</p> */}
            {/* /************************ */}
            <div className='text-justify card-text'>{props.children}</div>
            {/* /************************ */}
          </div>
        </div>
      </div>
    </>
  );
}
