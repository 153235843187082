import React from "react";
import { useContext, useEffect } from "react";

import CSL from "./CSL";
import Layout from "../layout/Layout";
import ConferenceContext from "../../context/conferenceContext";
import CardUI from "../../components/card/CardUI";
import Welcome from "./Welcome";
import WebsiteOverview from "./WebsiteOverview";
import Banner from "./Banner";

import "./home-style.css";
// import Overview from "../../components/common/Overview";

export default function HomePage() {
  const conferenceContext = useContext(ConferenceContext);
  const { getConferences, conferences, loading } = conferenceContext;

  useEffect(async () => {
    getConferences();
  }, []);

  return (
    <>
      {/* headerSection*/}
      {/* <br /> */}
      <Banner />
      <br />
      <Layout>
        {/* .................................... */}
        <CardUI title='Welcome' showBiggerTitle={true}>
          <Welcome />
        </CardUI>
        {/* .................................... */}
        <CardUI title='About this website' showBiggerTitle={false}>
          <WebsiteOverview />
        </CardUI>
        {/* .................................... */}
        {/* {!loading &&
          conferences.map((conf, idx) => (
            <OverviewCardUI
              title={conf.name}
              body={conf.about}
              showBiggerTitle={true}
              key={conf.id}
            />
          ))} */}
        {/* {loading && <div>Loading...</div>} */}
        {/* .................................... */}
        {/* {loading && (
          <div>
            <OverviewCardUI
              title={"Exchange 2020 Conference"}
              showBiggerTitle={true}
              key={"Conf-key-0"}
            />
          </div>
        )} */}
        {/* title='Community Service Learning (CSL)' */}
        <CSL
          title='Community Service Learning (CSL)'
          showBiggerTitle={false}
          key={"HomeCSLCards-2"}
        />
        ,
      </Layout>
    </>
  );
}
