import React from "react";
import { Link } from "react-router-dom";

export default function BreadcrumbDashboard(props) {
  const my_style = {
    color: "white",
    FontWeight: 650,
    fontFamily: "Arial, Tahoma, Verdana, Geneva, sans-serif",
    textShadow: "3px 2px 5px red",
  };
  return (
    <>
      <section id='breadcrumb'>
        <div className='container'>
          <ol className='breadcrumb'>
            <li className='active'>
              <Link to='/dashboard'>Dashboard</Link>
            </li>
            <li className='active ml-2' style={my_style}>
              {props.page_name}
            </li>
            <li className='active ml-2' style={my_style}>
              {props.inner_page_name}
            </li>
          </ol>
        </div>
      </section>
    </>
  );
}
