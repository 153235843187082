import React from "react";

const ShowUploadedFile = ({ file_name, file_path }) => {
  return (
    <>
      <div className='row mt-5'>
        <div className='col-md-6 m-auto'>
          {/* <h6 className='text-center'>Uploaded Filename: {file_name}</h6> */}
          <h6 className='text-center'>{file_name ? "Uploaded File:" : null}</h6>
          <img style={{ width: "100%" }} src={file_path} alt='' />
        </div>
      </div>
    </>
  );
};

export default ShowUploadedFile;
