import React, { useState, useEffect } from "react";
import Axios from "axios";

import { Link, useHistory } from "react-router-dom";
import capitalize from "../../services/capitalize";
import errorHandler from "../../services/errorHandler";
import {
  getConferences,
  getPresentationTypes,
} from "../../services/conferenceService";

import DashboardLayout from "../layout/DashboardLayout";
import CardContent from "../common/CardContent";

const AddPaper = () => {
  const [file, setFile] = useState("");
  const [filename, setFilename] = useState(
    "Choose file (with extension .pdf) "
  );
  const [loading, setLoading] = useState(false);
  const [conferences, setConferences] = useState([]);
  const [presentationTypes, setPresentationTypes] = useState([]);

  const [paper, setPaper] = useState({
    title: "",
    author: "",
    affiliation: "",
    abstract: "",
    keywords: "",
    first_page_number: "",
    last_page_number: "",
    additional_number: "",
    paper_link: "",
    published_on: "",
    uploaded_file: "",
    conference_id: 0,
    presentation_id: 0,
  });

  const [errors, setErrors] = useState({
    title: "",
    author: "",
    abstract: "",
    keywords: "",
    first_page_number: "",
    last_page_number: "",
  });

  const history = useHistory();

  const onChange = (e) => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
    console.log("file:", file);
  };

  const handleChange = (e) => {
    isEmpty(e);
    setPaper({ ...paper, [e.target.name]: e.target.value });
    console.log(("paper:", paper));
  };

  const isEmpty = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (!value) {
      let error_msg = `${capitalize(name)} field cannot be empty`;
      setErrors({ ...errors, [e.target.name]: error_msg });
    }
  };

  const handleValidation = (paper, errors) => {
    const {
      title,
      author,
      abstract,
      keywords,
      first_page_number,
      last_page_number,
    } = paper;

    let total_err = 0;
    if (!title) {
      errors.title = "Title is required.";
      total_err++;
      // setErrors({ ...errors, title: "Title is required" });
    }
    if (!abstract) {
      errors.abstract = "Abstract is required.";
      total_err++;
      // setErrors({ ...errors, abstract: "Article abstract is required" });
    }
    if (!author) {
      errors.author = "Author is required.";
      total_err++;
      // setErrors({ ...errors, author: "Author is required"});
    }
    if (!keywords) {
      errors.keywords = "keywords are required.";
      total_err++;
      // setErrors({ ...errors, keywords: "keywords are required",});
    }
    if (!first_page_number) {
      errors.first_page_number = "First page number is required.";
      total_err++;
      //setErrors({ ...errors, first_page_number: "First page number is required." });
    }
    if (!last_page_number) {
      errors.last_page_number = "Last page number is required.";
      total_err++;
      //setErrors({ ...errors, last_page_number: "Last page number is required." });
    }
    //console.log("Errors:", errors);
    return total_err;
  };

  //*** Sending data to Back-end by POST request *** */
  const submitContent = async (e) => {
    e.preventDefault();
    const total_err = handleValidation(paper, errors);

    if (total_err === 0) {
      // paper.uploaded_file = file;

      const formData = new FormData();
      formData.append("file", file);
      formData.append("paper", paper);
      //paper.uploaded_file = formData;

      console.log("No input error(s) found, and file:", formData);
      await Axios.post(
        "/api/papers/add-paper",
        paper
        // {
        // headers: {
        //   // "Content-Type": "multipart/form-data",
        //   "Content-Type": "application/x-www-form-urlencoded",
        // },
        // }
      )
        .then(() => {
          alert("Successfully inserted the article!");
        })
        .catch((err) => {
          errorHandler(err);
        });

      // console.log(paper.title);
      // history.push("/dashboard-conferences");
    } else {
      console.log("Total " + total_err + " input error(s) found");
    }
  };

  useEffect(() => {
    setLoading(true);
    getConferences()
      .then((res) => {
        setConferences(res.data);
        console.log("Conference:", res.data);
        setLoading(false);
      })
      .catch((err) => {
        errorHandler(err);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    getPresentationTypes()
      .then((res) => {
        setPresentationTypes(res.data);
        console.log("PresentationTypes:", res.data);
        setLoading(false);
      })
      .catch((err) => {
        errorHandler(err);
      });
  }, []);

  return (
    <>
      <DashboardLayout page_name='Add Paper'>
        <CardContent title='Add Page'>
          {/*---- Start FORM-----*/}
          <form>
            <div className='form-row'>
              {/*******  Paper Title *******/}
              <div className='form-group col-md-12'>
                <input
                  type='text'
                  className='form-control'
                  name='title'
                  value={paper.title}
                  placeholder='Title of the paper *'
                  onChange={(e) => {
                    handleChange(e);
                    errors.title = "";
                  }}
                  required
                />
                {errors.title !== "" && (
                  <span style={{ color: "red" }}>{errors.title}</span>
                )}
              </div>

              {/*******  Author(s) *******/}

              <div className='form-group col-md-12'>
                <input
                  type='text'
                  className='form-control'
                  name='author'
                  value={paper.author}
                  onChange={handleChange}
                  placeholder='Author(s)*'
                />
              </div>
              {/*******  Affiliation*******/}

              <div className='form-group col-md-12'>
                <input
                  type='text'
                  className='form-control'
                  name='affiliation'
                  value={paper.affiliation}
                  onChange={handleChange}
                  placeholder='Affiliation Institute(s).'
                />
              </div>
              {/*******  Abstract *******/}
              <div className='form-group col-md-12'>
                <textarea
                  rows='5'
                  name='abstract'
                  className='form-control'
                  placeholder='Abstract of the paper*'
                  // defaultValue='Sample description of an event.'
                  id='description'
                  value={paper.abstract}
                  onChange={(e) => {
                    handleChange(e);
                    errors.abstract = "";
                  }}
                  required
                />
                {errors.abstract !== "" && (
                  <span style={{ color: "red" }}>{errors.abstract}</span>
                )}
              </div>

              {/*******  Keywords *******/}

              <div className='form-group col-md-12'>
                <input
                  type='text'
                  className='form-control'
                  name='keywords'
                  value={paper.keywords}
                  onChange={(e) => {
                    handleChange(e);
                    errors.keywords = "";
                  }}
                  placeholder='Keywords*'
                />

                {errors.keywords !== "" && (
                  <span style={{ color: "red" }}>{errors.keywords}</span>
                )}
              </div>

              {/******* First and last page numbers*******/}

              <div className='form-group col-md-6'>
                {/* <label htmlFor='first_page_number'> First page number:</label> */}
                <input
                  type='number'
                  className='form-control'
                  name='first_page_number'
                  value={paper.first_page_number}
                  placeholder='First page number*'
                  onChange={(e) => {
                    handleChange(e);
                    errors.first_page_number = "";
                  }}
                />
                {errors.first_page_number !== "" && (
                  <span style={{ color: "red" }}>
                    {errors.first_page_number}
                  </span>
                )}
              </div>

              <div className='form-group col-md-6'>
                {/* <label htmlFor='last_page_number'>  Last page number: </label> */}
                <input
                  type='number'
                  className='form-control'
                  name='last_page_number'
                  value={paper.last_page_number}
                  onChange={handleChange}
                  placeholder='Last page number*'
                />
                {errors.last_page_number !== "" && (
                  <span style={{ color: "red" }}>
                    {errors.last_page_number}
                  </span>
                )}
              </div>

              {/*******  Conference and Type of Presentation *******/}

              <div className='form-group col-md-6'>
                <label htmlFor='published_on'>Conference:</label>
                <select
                  className='form-control'
                  name='conference_id'
                  onChange={handleChange}
                >
                  {/* <option selected> Select a conference</option> */}
                  {conferences.map((data, idx) => (
                    <option key={idx} value={data.id}>
                      {data.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className='form-group col-md-6'>
                <label htmlFor='end_date'>Type of presentation:</label>
                <select
                  className='form-control'
                  name='presentation_id'
                  onChange={handleChange}
                >
                  {/* <option selected> Select a type</option> */}
                  {presentationTypes.map((data, i) => (
                    <option key={i} value={data.id}>
                      {data.type}
                    </option>
                  ))}
                </select>
              </div>

              {/*******  published_on and additional numbers *******/}
              <div className='form-group col-md-6'>
                <label htmlFor='published_on'>Published on:</label>
                <input
                  type='date'
                  className='form-control'
                  name='published_on'
                  value={paper.published_on}
                  onChange={handleChange}
                  placeholder='Published date*'
                />
              </div>

              <div className='form-group col-md-6'>
                <label htmlFor='end_date'>Additional number(s):</label>
                <input
                  type='text'
                  className='form-control'
                  name='additional_number'
                  value={paper.additional_number}
                  onChange={handleChange}
                  placeholder='Additional Number(s)'
                />
              </div>

              {/*******  paper_link *******/}
              <div className='form-group col-md-12'>
                <input
                  type='text'
                  className='form-control'
                  name='paper_link'
                  value={paper.paper_link}
                  onChange={handleChange}
                  placeholder='Paper url or web-link'
                />
              </div>

              {/*******  file upload *******/}
              {/* <div className='form-group col-md-7'>
                    <input
                      type='file'
                      className='form-control'
                      name='file_name'
                      value={paper.file_name}
                      onChange={handleChange}
                      placeholder='Upload the paper'
                    />
                  </div> */}

              {/* <div className='form-group col-md-3'>
                    <div className='input-group'>
                      <div className='input-group-prepend'>
                        <div className='input-group-text'>$</div>
                      </div>
                      <input
                        type='text'
                        name='price'
                        className='form-control'
                        id='price0'
                        placeholder='Price (optional)'
                      />
                    </div>
                  </div> */}

              {/* <div className='form-group col-md-2'>
                    <button
                      type='submit'
                      className='btn btn-primary'
                      onClick={submitContent}
                    >
                      Upload
                    </button>
                  </div> */}

              {/* ----------------------- */}
              <div className='custom-file mb-4'>
                <input
                  type='file'
                  className='custom-file-input'
                  name='title'
                  value={paper.uploaded_file}
                  accept=''
                  id='customFile'
                  onChange={(e) => {
                    onChange(e);
                    handleChange(e);
                  }}
                />
                <label className='custom-file-label' htmlFor='custom_file'>
                  {filename}
                </label>
              </div>

              {/* <Progress percentage={uploadPercentage} /> */}
            </div>

            {/*******  Submit Button  *******/}
            <button
              type='submit'
              className='btn btn-info mt-3'
              onClick={submitContent}
            >
              {/* <i
                            className='fa fa-arrow-circle-o-up'
                            aria-hidden='true'
                          ></i>{" "} */}
              Submit
            </button>
            <Link to='/dashboard' className='btn btn-danger ml-3 mt-3'>
              Cancel
            </Link>
          </form>
        </CardContent>
      </DashboardLayout>
    </>
  );
};

export default AddPaper;
