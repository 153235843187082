import React from "react";
import { NavLink } from "react-router-dom";
// import { Link } from "react-router-dom";
// import { Nav, Navbar, NavDropdown } from "react-bootstrap";

//import NavbarTop from "./NavbarTop";
import logo from "../../assets/images/logo/RE-logo-navbar.png";
//import logo from "../../assets/images/logo/KE-logo.png";

export default function Navigation() {
  return (
    <>
      <div>
        <nav className='navbar navbar-expand-lg navbar-light bg-light'>
          <div className='container'>
            <NavLink to='/' className='navbar-brand'>
              <img src={logo} alt='Logo' title='Logo' />
            </NavLink>
            <button
              className='navbar-toggler'
              type='button'
              data-toggle='collapse'
              data-target='#navbarTogglerResponsive'
              aria-controls='navbarTogglerDemo01'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='navbar-toggler-icon'></span>
            </button>

            <div
              className='collapse navbar-collapse'
              id='navbarTogglerResponsive'
            >
              <ul className='navbar-nav ml-auto'>
                <li className='nav-item'>
                  <NavLink
                    exact
                    to='/'
                    className='nav-link'
                    activeClassName='active'
                  >
                    Home
                  </NavLink>
                </li>
                {/* <li className='nav-item'>
                  <NavLink to='/about' className='nav-link'>
                    About
                  </NavLink>
                </li> */}
                <li className='nav-item'>
                  <NavLink to='/conference' className='nav-link'>
                    Conference
                  </NavLink>
                </li>
                {/* Event menu hidden */}
                {/* <li className='nav-item'>
                  <NavLink to='/events' className='nav-link'>
                    Events
                  </NavLink>
                </li> */}
                <li className='nav-item'>
                  <NavLink to='/resources' className='nav-link'>
                    Videos
                  </NavLink>
                </li>
                <li className='nav-item'>
                  <NavLink to='/contact' className='nav-link'>
                    Contact
                  </NavLink>
                </li>
                {/* <li className='nav-item'>
                  <NavLink to='/admin' className='nav-link'>
                    Admin
                  </NavLink>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>

        {/* ........................... */}
        {/* 
        <Navbar collapseOnSelect expand='lg' bg='light' variant='light'>
          <div className='container'>
            <Navbar.Brand as={Link} to='/'>
              <img src={logo} alt='Logo' title='Logo' />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls='responsive-navbar-nav align-center' />
            <Navbar.Collapse id='responsive-navbar-nav'>
              <Nav className='mr-auto'>
                <Nav.Link as={Link} to='/'>
                  Home
                </Nav.Link>
                <Nav.Link as={Link} to='/about'>
                  About
                </Nav.Link>
                <Nav.Link as={Link} to='/conference'>
                  Conference
                </Nav.Link>
                <Nav.Link as={Link} to='/events'>
                  Events
                </Nav.Link>
                <Nav.Link as={Link} to='/resources'>
                  Resources
                </Nav.Link>

                <NavDropdown title='Dropdown' id='collasible-nav-dropdown'>
                  <NavDropdown.Item href='#action/3.1'>Action</NavDropdown.Item>
                  <NavDropdown.Item href='#action/3.2'>
                    Another action
                  </NavDropdown.Item>
                  <NavDropdown.Item href='#action/3.3'>
                    Something
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href='#action/3.4'>
                    Separated link
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={Link} to='/contact/create'>
                  Create Contact
                </Nav.Link>
                <Nav.Link href='#deets1'>Logout</Nav.Link>
              </Nav>

              <Nav>
                <Nav.Link as={Link} to='/dashboard/users'>
                  Admin
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar> */}
      </div>
    </>
  );
}
