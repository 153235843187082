export const dataExchange2020 = [
  {
    id: 1,
    conference_id: 2,
    presentation_id: 1,
    title:
      "People with Lived Experience in Roles of Leadership within Community Organizations",
    author:
      "Sarah Tremblett, Emily Wadden, Lilian Klein, Christina Traverse, Melendy Muise, & Tiffany Halliday",
    abstract:
      'This presentation provides an update on ongoing public engagement and research activities that resulted from this team’s Exchange 2018 presentation, entitled "Sexual Exploitation, Trauma and Attachment: Implications for Community-based Social Work". The current project focuses on the value of employing people with lived experiences (PWLE) in roles of leadership within community-based organizations (CBOs). It includes a literature review as well as information gathered in collaboration with individuals who have experiences of exploitation and are now in positions of leadership within local CBOs. The presenters highlight how the experiences of these individuals connect to conclusions made in existing literature. Finally, the need for formalized roles for PWLE within CBOs is discussed.',
    keywords: "sexual exploitation, community-based, social work, leadership",
    first_page_number: 1,
    last_page_number: 10,
    additional_number: "",
    paper_link: "https://www.researchgate.net/project/Exchange-2020",
    published_on: "2020-03-04T05:00:00.000Z",
    file_name: "",
    affiliation: null,
  },
  {
    id: 2,
    conference_id: 2,
    presentation_id: 1,
    title: "Informing Social Work Regulation in the North",
    author:
      "Bianca Martin, Beckie Huston, & the Association of Social Workers in Northern Canada (community partner)",
    abstract:
      "A synopsis of a research report submitted to the Association of Social Workers in Northern Canada (ASWNC) is presented. The report looks at the issue of Social Work Regulation in the North. Two presenters discuss their experiences developing this report, first as students working on a community service learning assignment, and then as paid student assistants on the scale-up of this assignment. They also reflect on their experiences presenting their report to members of the ASWNC who are living/working in Nunavut and the Northwest Territories. Notably, the report will inform/influence Northern policy.",
    keywords: "oncology, psychosocial benefits, cancer diagnosis",
    first_page_number: 1,
    last_page_number: 10,
    additional_number: "",
    paper_link: "https://www.researchgate.net/project/Exchange-2020",
    published_on: "2020-03-04T05:00:00.000Z",
    file_name: "",
    affiliation:
      "Association of Social Workers in Northern Canada (community partner)",
  },
  {
    id: 3,
    conference_id: 2,
    presentation_id: 1,
    title: "Exploring Harm Reduction Strategies for Cannabis",
    author:
      "Jenny Brace, Claire Riggs, Heather Bartlett and Daybreak Child & Family Centre (community partner)",
    abstract:
      "Based on a scoping review of the literature, few existing harm reduction efforts are neutral. Among the diverse voices found in published documents, two prominent ones are health authority reports, which focus on risks or adverse effects, and vendors / user groups that stress the medicinal value of Cannabis and other benefits, providing information on safe consumption. Few efforts to reconcile messages were found. In partnership with Daybreak Child and Family Centre, students and community workers present a non-judgmental report that integrates harm reduction literature and practice knowledge. Presenters also discuss initial steps towards a participatory research project to gather user knowledge and develop a workshop for promoting harm reduction in community organizations.",
    keywords: "harm reduction, cannabis, Daybreak Child, Family Centre",
    first_page_number: 1,
    last_page_number: 10,
    additional_number: "",
    paper_link: "https://www.researchgate.net/project/Exchange-2020",
    published_on: "2020-03-04T05:00:00.000Z",
    file_name: "",
    affiliation: "Daybreak Child & Family Centre (community partner)",
  },
  {
    id: 4,
    conference_id: 2,
    presentation_id: 1,
    title: "Do Oncology Camps Promote Mental Wellness?",
    author:
      "Jared Davis, Jessica Courage, Lisa Blundell, Guy Courage,  The Candle lighters Association of Newfoundland & Labrador (community partner)",
    abstract:
      "Oncology camps are becoming increasingly popular in North America, providing respite for children and families who are dealing with a cancer diagnosis and associated challenges (e.g., treatment side effects). However, do campers and their families gain tangible benefits to health and wellbeing from participation in these programs? A brief review of evaluative research examining the psychosocial benefits of Oncology camps is presented, along with reflections on how this knowledge benefits the Candlelighters of NL. Presenters also speak of their experiences seeking funding, noteworthy aspects of the project (e.g., working with scholars and community partners in other provinces), lessons learned, and skills gained from the partnership project.",
    keywords: "community partners, mental wellness, candlelighters ",
    first_page_number: 1,
    last_page_number: 10,
    additional_number: "",
    paper_link: "https://www.researchgate.net/project/Exchange-2020",
    published_on: "2020-03-04T05:00:00.000Z",
    file_name: "",
    affiliation:
      "Association of Social Workers in Northern Canada (community partner)",
  },
  {
    id: 6,
    conference_id: 2,
    presentation_id: 1,
    title: "Support Matters! For Parents of Children with Neurodisabilities",
    author: "Lucyna Lach",
    abstract:
      'The successes and lessons learned from Dr. Lach’s recent public engagement efforts are presented, highlighting the positive outcomes of major projects to build research capacity in social work contexts (e.g., more sensitive practice by raising awareness of hidden realities of clients). Dr. Lach presents examples of how to integrate research into the daily practice of social workers and how specific results can inspire changes in practice. Through an "integrated knowledge translation" model, social workers and communities build, implement and utilize research to ensure that practice is aligned with the needs of those they serve.',
    keywords: "Neurodisabilities, knowledge translation, social work",
    first_page_number: -1,
    last_page_number: 10,
    additional_number: "",
    paper_link: "https://www.researchgate.net/project/Exchange-2020",
    published_on: "2020-03-04T05:00:00.000Z",
    file_name: "",
    affiliation:
      "Centre for Research on Children and Families (CRCF), McGill University, Montreal, Quebec",
  },
];
