import React from "react";
import { useState } from "react";
import Axios from "axios";
import DashboardLayout from "../layout/DashboardLayout";
import { Link, useHistory } from "react-router-dom";
import CardContent from "../common/CardContent";
//import PagesMainSection from "../PagesMainSection";
// import CKEditorTest from "../CKEditorTest"; <CKEditorTest />

export default function AddEvent() {
  const [name, setName] = useState(" ");
  const [description, setDescription] = useState("");
  const [presenters, setPresenters] = useState("");
  const [eventStartDate, setEventStartDate] = useState("");
  const [eventEndDate, setEventEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [location, setLocation] = useState("");
  const [joiningLink, setJoiningLink] = useState("");

  const [contentList, setContentList] = useState([]);

  const history = useHistory();

  //********** Sending data to Back-end (then we will Insert content to Database) by POST request****** */
  const submitContent = (e) => {
    e.preventDefault();
    Axios.post("/api/events/add-event", {
      name: name,
      description: description,
      presenters: presenters,
      start_date: eventStartDate,
      start_time: startTime,
      end_date: eventEndDate,
      end_time: endTime,
      location: location,
      joining_link: joiningLink,
    }).then(() => {
      alert("Successfully inserted the event!");
      console.log(name);
    });

    console.log(name);

    setContentList([
      ...contentList,
      {
        name: name,
        description: description,
        presenters: presenters,
        start_date: eventStartDate,
        start_time: startTime,
        end_date: eventEndDate,
        end_time: endTime,
        location: location,
        joining_link: joiningLink,
      },
    ]);
    history.push("/dashboard-events");
  };

  return (
    <>
      <DashboardLayout page_name='' inner_page_name='Add Event'>
        <CardContent title='Add Event'>
          <div className='form'>
            {/* <form> */}
            <form className='form-group'>
              <label>Event Name:</label>
              <input
                type='text'
                className='form-control'
                placeholder='Please add title of an event.'
                defaultValue='Sample title of an event.'
                onChange={(e) => {
                  setName(e.target.value);
                }}
                required
              />

              <label>Event Description:</label>
              <textarea
                rows='5'
                className='form-control'
                placeholder='Please enter the description of the event.'
                // defaultValue='Sample description of an event.'
                id='description'
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                required
              ></textarea>

              <label>Presenters: </label>
              <input
                type='text'
                className='form-control'
                placeholder='Please enter presenter name(s).'
                defaultValue='Dr. Stephen Ellenbogen, Dr. Julia Janes'
                onChange={(e) => {
                  setPresenters(e.target.value);
                }}
                required
              />

              <label> Start Date: </label>
              <input
                type='date'
                className='form-control input-group-lg'
                placeholder='Add date...'
                // defaultValue='2020-11-02 '
                onChange={(e) => {
                  setEventStartDate(e.target.value);
                }}
                required
              />

              <label htmlFor='start_time'>Start Time: </label>
              <input
                type='time'
                name='start_time'
                className='form-control'
                placeholder='Add time...'
                defaultValue=' 12:30:00'
                onChange={(e) => {
                  setStartTime(e.target.value);
                }}
                required
              />

              <label> End Date: </label>
              <input
                type='date'
                className='form-control'
                placeholder='Add date...'
                // defaultValue='2020-11-02 '
                onChange={(e) => {
                  setEventEndDate(e.target.value);
                }}
                required
              />

              <label>End Time: </label>
              <input
                type='text'
                className='form-control'
                placeholder='Add time...'
                defaultValue=' 14:30:00'
                onChange={(e) => {
                  setEndTime(e.target.value);
                }}
                required
              />

              <label>Location: </label>
              <input
                type='text'
                className='form-control'
                placeholder='Add location...'
                defaultValue=' Memorial University'
                onChange={(e) => {
                  setLocation(e.target.value);
                }}
                required
              />

              <label>Joining link: </label>
              <input
                type='text'
                className='form-control'
                placeholder='Add link...'
                defaultValue=' https://meet.google.com/pnx-vecc-axq'
                onChange={(e) => {
                  setJoiningLink(e.target.value);
                }}
              />

              {/* <div className='checkbox'>
                      <label>
                        <input type='checkbox' checked /> Published
                      </label>
                    </div>
                    <div className='form-group'>
                      <label>Meta Tags</label>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Add Some Tags...'
                        value='tag1, tag2'
                      />
                    </div> */}

              {/* <input
                      type='submit'
                      className='btn btn-success'
                      value='Submit'
                    /> */}
              {/*******  Submit Button  *******/}
              <button
                type='submit'
                className='btn btn-info mt-3'
                onClick={submitContent}
              >
                Submit
              </button>
              <Link to='/dashboard' className='btn btn-danger ml-3 mt-3'>
                Cancel
              </Link>
            </form>
            {/* </form> */}
          </div>
        </CardContent>
      </DashboardLayout>
    </>
  );
}
