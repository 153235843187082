import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Partners from "./components/common/Partners";
import Routes from "./routes/Routes";

function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes />
        <Partners />
        <Footer />
      </Router>
    </>
  );
}
export default App;
