import React, { useState } from "react";
import "./home-style.css";
import { NavLink } from "react-router-dom";

export default function Welcome() {
  const [show, setShow] = useState(false);
  const paragraph1 =
    "This website describes an educational a knowledge exchange program created by professors at Memorial University’s School of Social Work to foster collaborations with community-based organizations.";
  const paragraph2 = (
    <div style={{ textAlign: "justify", textJustify: "interWord" }}>
      This website describes an educational a knowledge exchange program created
      by professors at Memorial University’s School of Social Work to foster
      collaborations with community-based organizations. The program comprises
      three level of engagement: <br />{" "}
      <ol id='menu'>
        <li>
          All projects begin as short-term collaborations. Bachelors students
          engage with community organizations and complete Community Service
          Learning{" "}
          {/* <strong>
            <a>(Page 4)</a>
          </strong> */}
          assignments for a course on community development.
        </li>
        <li>
          For several promising assignments, agreements were reached to continue
          the collaboration into medium-term
          <NavLink to='/outcomes'> scale-up projects</NavLink>, in which teams
          work toward a paper is presented at Exchange conferences. Examples of
          presentations:{" "}
          {/* <strong>
            <a>(Page 2)</a>
          </strong> */}
        </li>
        <li>
          Some scale-up projects mature into
          <NavLink to='/projects'> long-term partnership projects</NavLink>,
          united around interest in collaborating on research and knowledge
          mobilization activities. Examples of such partnerships: Harm
          Reduction, Candlelighters, People with lived experience, Social work
          registration{" "}
          {/* <strong>
            <a>(Page 3).</a>
          </strong>{" "} */}
        </li>
      </ol>{" "}
      Our School of Social Work has a long history of community service
      learning.
      {/* Here are some other professors’ projects: ### */}
      {/* <ol id='menu'>
        <li>Project-1: ###</li>
        <li>Project-1: ###</li>
        <li>Project-1: ###</li>
      </ol> */}
    </div>
  );
  return (
    <div>
      <p className='p-font-size'>{paragraph2}</p>
    </div>
  );
}
