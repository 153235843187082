import React, { useState } from "react";
import { useGlobalState } from "../../context/StateProvider";
import { v4 as uuid } from "uuid";
import { Link, useHistory } from "react-router-dom";
import Axios from "axios";
//import { Axios } from "../../config";
import AdminHeader from "./AdminHeader";

export default function Register() {
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [password_match, setPasswordMatch] = useState(true);
  const [satisfy_length, setSatisfyLength] = useState(true);
  const [message, setMessage] = useState("");
  const [completed, setCompleted] = useState(false);

  // const { addUser } = useContext(GlobalContext);
  const { users, addUser } = useGlobalState();
  const history = useHistory();
  console.log("Total users : " + users.length);

  const clearInputFields = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
  };

  // Register the user by POST Method//
  const registerUser = async (new_user) => {
    await Axios.post("/api/auth/register", new_user)
      .then((res) => {
        // Registration successful
        if (res.data.reg_status) {
          setMessage(res.data.message);
          setCompleted(true);
          // alert("MSG: " + res.data.message);

          // Add user's some information to the store
          let dt = new Date().toISOString();
          const { first_name, last_name, email } = new_user;
          const new_user_some_info = {
            id: uuid(),
            first_name,
            last_name,
            email,
            created_on: dt,
          };
          addUser(new_user_some_info);

          // Clear the input fields - since  the user successfully registered.
          clearInputFields();
          history.push("/register");
        } else {
          // Input validation error message form Backend
          if (res.data.errors) {
            setMessage(res.data.errors[0].msg);
          } else {
            setMessage(res.data.message); // Other msg from server
          }
        }
      })
      .catch((err) => {
        alert("Submission not successful ! Error: " + err);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let new_user = {
      // id: uuid(),
      first_name: first_name,
      last_name: last_name,
      email: email,
      password: password,
      confirm_password: confirm_password,
    };
    if (password.length < 6) {
      setSatisfyLength(false);
      // setMessage("Please enter a password with 6 or more characters.");
    } else if (password !== confirm_password) {
      setPasswordMatch(false);
    } else {
      // Register the user by POST method//
      registerUser(new_user);
    }
  };

  const onChangeFirstNameHandler = (e) => {
    setFirstName(e.target.value);
    setCompleted(false);
    setMessage("");
  };

  const onChangeLastNameHandler = (e) => {
    setLastName(e.target.value);
    setCompleted(false);
    setMessage("");
  };

  const onChangeEmailHandler = (e) => {
    setEmail(e.target.value);
    setCompleted(false);
    setMessage("");
  };

  const onChangePasswordHandler = (e) => {
    setPassword(e.target.value);
    setSatisfyLength(true);
    setPasswordMatch(true);
    setCompleted(false);
    setMessage("");
  };

  const onChangeConfirmPasswordHandler = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordMatch(true);
    setCompleted(false);
    setMessage("");
  };

  return (
    <>
      <AdminHeader title='User Registration' />
      <section>
        <div className='container mb-3'>
          <div className='text-center mt-3'>
            {/* <h5>
              <strong> Add User</strong>
            </h5> */}
            {message ? (
              <p className={completed ? "text-success" : "text-danger"}>
                {" "}
                {message}
              </p>
            ) : null}
          </div>
          <div className='row'>
            <div className='col-md-6 offset-md-3'>
              <form className='well well-lg' onSubmit={onSubmit}>
                <div className='form-group text-left'>
                  <label> First Name: </label>
                  <input
                    className='form-control'
                    type='text'
                    value={first_name}
                    name='first_name'
                    onChange={onChangeFirstNameHandler}
                    placeholder='Stephen'
                    required
                  ></input>
                </div>

                <div className='form-group text-left'>
                  <label> Last Name: </label>
                  <input
                    className='form-control'
                    type='text'
                    value={last_name}
                    name='last_name'
                    onChange={onChangeLastNameHandler}
                    placeholder='Ellenbogen'
                    required
                  ></input>
                </div>

                <div className='form-group text-left'>
                  <label> Email Address: </label>
                  <input
                    className='form-control'
                    type='email'
                    value={email}
                    name='email'
                    onChange={onChangeEmailHandler}
                    placeholder='example@email.com'
                    required
                  ></input>
                </div>

                <div className='form-group text-left'>
                  <label>Password:</label>
                  <input
                    className='form-control'
                    type='password'
                    value={password}
                    onChange={onChangePasswordHandler}
                    // pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}'
                    placeholder='Password'
                    autoComplete='new-password'
                    required
                  />
                </div>

                {!satisfy_length && (
                  <p className='text-danger'>
                    {"Please enter a password with 6 or more characters!"}
                  </p>
                )}

                <div className='form-group text-left'>
                  <label>Confirm Password:</label>
                  <input
                    className='form-control'
                    type='password'
                    name='confirm_password'
                    value={confirm_password}
                    onChange={onChangeConfirmPasswordHandler}
                    placeholder='Confirm Password'
                    autoComplete='new-password'
                    required
                  />
                  {!password_match && (
                    <p className='text-danger'>{"Password does not match!"}</p>
                  )}
                </div>

                <button className='btn btn-success' type='submit'>
                  Submit
                </button>
                <Link to='/users' className='btn btn-danger ml-2'>
                  Cancel
                </Link>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
