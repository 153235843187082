import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getEvents, deleteEvent } from "../../services/eventService";
import { formatDate, formatTime } from "../common/FormatDateTime";
import {
  getConferences,
  deleteConference,
} from "../../services/conferenceService";
import errorHandler from "../../services/errorHandler";

// import { useHistory} from "react-router-dom";
// import { v4 as uuid } from "uuid";

const ConferenceListing = (props) => {
  const [loading, setLoading] = useState(false);
  const [conferences, setConferences] = useState([]);

  //*** GET Conferences ****** */
  useEffect(() => {
    setLoading(true);
    getConferences()
      .then((res) => {
        setConferences(res.data);
        console.log("Conference:", res.data);
        setLoading(false);
      })
      .catch((err) => {
        errorHandler(err);
      });
  }, []);

  //*** DELETE ****** */
  const deleteContent = (id) => {
    deleteConference(id)
      .then(() => {
        alert("Successfully deleted the item " + id);
        delete conferences.id;
        setConferences(conferences);
      })
      .catch((err) => errorHandler(err));
    return;
  };

  // const deleteContent = (id) => {
  //   Axios.delete(`/api/events/delete-event/${id}`).then(() => {
  //     alert("Successfully Deleted the item -" + id);
  //     console.log(
  //       "ID - " + parseInt(id) + " has sent for deleting the event."
  //     );
  //     delete conferences.id;
  //     setConferences(conferences);
  //   });
  //   return;
  // };

  //**********Send PUT request to Back-end for updating a content from database****** */
  // const updateContent = (content_id) => {
  //   Axios.put(`http://localhost:8000/api/update-content/${content_id}`, {
  //     //content_id: content_id,
  //     question: newQuestion,
  //   }).then(() => {
  //     //alert("Successfully Updated!");
  //     console.log(
  //       "ID-" + content_id + " has sent for updating the content:" + newQuestion
  //     );
  //   });

  //   console.log(
  //     "ID-" + content_id + " has sent for updating the content:" + newQuestion
  //   );
  //   setNewQuestion("");
  // };

  return (
    <>
      {/*----  start Users section <SiteOverview />-----*/}
      <div className='card mb-3' style={{ boxShadow: "0 0 white" }}>
        <div className='card-header main-color-bg '>
          <div className='card-title new-card-title'> Conferences</div>
        </div>
        {/*---- Start User body-----*/}
        <div className='card-body mx-4'>
          <div className='row'>
            <div className='col-md-12'>
              <input
                className='form-control'
                type='text'
                placeholder='Filter Users...'
              />
            </div>
          </div>
          <br />

          {loading && <h6 className='text-center'> Loading ... </h6>}

          {!loading && (
            <div>
              <table className='table table-striped table-hover mt-0'>
                <thead>
                  <tr>
                    <th scope='col'>SL</th>
                    <th scope='col'> Date</th>
                    <th scope='col'> Conference</th>
                    <th scope='col'> Venue</th>
                    <th scope='col'> </th>
                  </tr>
                </thead>
                <tbody>
                  {conferences.map((data, idx) => {
                    return (
                      <tr key={idx}>
                        <th scope='row'>{idx + 1}.</th>
                        <td>{formatDate(data.start_date)}</td>
                        <td>{data.name}</td>
                        <td>{data.venue}</td>
                        <td>
                          <Link
                            to={`/dashboard-edit-conference/${data.id}`}
                            className='btn btn-warning mr-2'
                            conference={data}
                          >
                            Edit
                          </Link>

                          <button
                            className='btn btn-danger'
                            onClick={() => deleteContent(data.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      {/*----  End Users section <SiteOverview />----*/}
    </>
  );
};

export default ConferenceListing;
