import React from "react";

export default function SiteOverview() {
  return (
    <>
      {/*---- Website Overview -----*/}
      <div className='card' style={{ boxShadow: "0 0 white" }}>
        <div className='card-header main-color-bg mb-3'>
          <div className='card-title new-card-title'>Website Overview</div>
        </div>
        {/*---- Start Website Overview  body-----*/}
        <div className='card-body'>
          <div className='row'>
            <div className='col-md-3'>
              <div className='well dash-box'>
                <h2>
                  <span
                    className='fa fa-user fa-lg px-2'
                    aria-hidden='true'
                  ></span>
                  203
                </h2>
                <h4>Users</h4>
              </div>
            </div>

            <div className='col-md-3'>
              <div className='well dash-box'>
                <h2>
                  <span
                    className='fa fa-newspaper-o fa-lg px-2'
                    aria-hidden='true'
                  ></span>
                  121
                </h2>
                <h4>Pages</h4>
              </div>
            </div>

            <div className='col-md-3'>
              <div className='well dash-box'>
                <h2>
                  <span
                    className='fa fa-pencil fa-lg px-2'
                    aria-hidden='true'
                  ></span>
                  203
                </h2>
                <h4>Posts</h4>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='well dash-box'>
                <h2>
                  <span
                    className='fa fa-line-chart fa-lg px-2'
                    aria-hidden='true'
                  ></span>
                  203
                </h2>
                <h4>Visitors</h4>
              </div>
            </div>
          </div>
        </div>
        {/*---- End Website Overview  body section-----*/}
      </div>
      {/*---- End Website Overview -----*/}
    </>
  );
}
