import React, { useState, useEffect, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../context/StateProvider";
import { formatDate } from "../common/FormatDateTime";
import Axios from "axios";
// import { v4 as uuid } from "uuid";

const UserListing = () => {
  const [userList, setUserList] = useState([]);
  const { users, addUser, removeUser } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);

  // const history = useHistory();

  // const addToStore = (userList) => {
  //   userList.map((user) => {
  //     const newUser = {
  //       id: user.id,
  //       first_name: user.first_name,
  //       last_name: user.last_name,
  //       email: user.email,
  //     };
  //     addUser(newUser);
  //   });
  //   // return history.push("/users");
  // };

  // // addToStore(userList);
  // const tags = useMemo(() => {
  //   return addToStore(userList);
  // }, [users, userList]);

  //~~~~~~~~ GET the user list from Back-end by GET request ~~~~~~~~/
  useEffect(async () => {
    setLoading(true);
    await Axios.get("/api/get-users")
      .then((res) => {
        setUserList(res.data);
        setLoading(false);
        // addToStore(userList);
      })
      .catch((err) => {
        alert(" Failed to fetch data" + err);
      });
  }, []);

  console.log(" Users length: " + users.length);
  console.log(" UserList data length: " + userList.length);

  // //   //~~~~~~~~~~~~~/
  //   const addToGlobalState = (e) => {
  //     e.preventDefault();

  //     const newUser = {
  //       id: uuid(),
  //       first_name: firstName,
  //       last_name: lastName,
  //       email: email,
  //       // password: password,
  //     };
  //     addUser(newUser);
  //     history.push("/users");
  //     };

  return (
    <div className='card mb-3' style={{ boxShadow: "0 0 white" }}>
      <div className='card-header main-color-bg '>
        <div className='card-title new-card-title'> Users</div>
      </div>
      {/*---- Start User body-----*/}
      <div className='card-body mx-4'>
        <div className='row'>
          <div className='col-md-12'>
            <input
              className='form-control'
              type='text'
              placeholder='Filter Users...'
            />
          </div>
        </div>
        <br />

        {loading && <h6 className='text-center'> Loading ... </h6>}

        <div className='list-group mt-4'>
          {userList.length > 0 ? (
            <>
              <table className='table table-striped table-hover'>
                <thead>
                  <tr>
                    <th scope='col'>SL</th>
                    <th scope='col'> Name</th>
                    <th scope='col'> Email</th>
                    <th scope='col'> Joined </th>
                    <th scope='col'> </th>
                  </tr>
                </thead>
                <tbody>
                  {userList.map((user, i) => (
                    <tr key={i}>
                      <th scope='row'>{i + 1}</th>
                      <td>
                        {user.first_name} {user.last_name}
                      </td>
                      <td>{user.email}</td>
                      <td> {formatDate(user.created_on)}</td>
                      <td>
                        <Link
                          to={`/edit-user/${user.id}`}
                          color='warning'
                          className='btn btn-warning mr-2'
                        >
                          Edit
                        </Link>
                        <button
                          className='btn btn-danger'
                          onClick={() => removeUser(user.id)}
                          color='danger'
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* ...............................  */}
              {/* {userList.map((user, i) => (
            <div className='list-group-item d-flex' key={user.id}>
              <strong>
                {i + 1}. &nbsp;&nbsp; {user.first_name} {user.last_name}{" "}
                &nbsp;&nbsp; {user.email}
              </strong>
              <div className='ml-auto'>
                <Link
                  to={`/edit-user/${user.id}`}
                  color='warning'
                  className='btn btn-warning mr-2'
                >
                  Edit
                </Link>
                <button
                  className='btn btn-danger'
                  onClick={() => removeUser(user.id)}
                  color='danger'
                >
                  Delete
                </button>
              </div>
            </div>
          ))} */}
              {/* ...............................  */}
            </>
          ) : (
            <h4 className='text-center'>No Users</h4>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserListing;
