export const dataExchange2020poster = [
  {
    id: 1,
    conference_id: 2,
    presentation_id: 1,
    title: "How Can Community-Based Organizations Engage Rural Volunteers?",
    author: "Tina Bankovic and Haleigh Fleming",
    abstract:
      "Journey mapping is a tool used by many for-profit organizations to track the stages of their customers’ experiences and can also be a useful way for a CBO to identify stages in their volunteers’ journeys. We consulted several volunteer recruitment guides, then adapted the journey map concept to identify the stages a CBO itself may undergo in engaging rural volunteers. We created the steps and stages below based on recurring themes in the guides. ",
    keywords: "sexual exploitation, community-based, social work, leadership",
    first_page_number: 1,
    last_page_number: 10,
    additional_number: "",
    paper_link: "https://www.researchgate.net/project/Exchange-2020",
    published_on: "2020-03-04T05:00:00.000Z",
    file_name: "",
    affiliation: "Memorial University of Newfoundland",
  },
  {
    id: 2,
    conference_id: 2,
    presentation_id: 1,
    title: "Critical Reflection on Kinship Care ",
    author: "Danielle Tulk, bsw student",
    abstract:
      "Research on kinship care indicates both short-term benefits and challenges to this intervention. There is a lack of longitudinal research; little is known of long-term outcomes. The development of evidence-informed practice models, and training of BSW students on these models, are key to improving this facet of child protection.",
    first_page_number: 1,
    last_page_number: 10,
    additional_number: "",
    paper_link: "https://www.researchgate.net/project/Exchange-2020",
    published_on: "2020-03-04T05:00:00.000Z",
    file_name: "",
    affiliation: "Memorial University of Newfoundland",
  },
  {
    id: 3,
    conference_id: 2,
    presentation_id: 1,
    title:
      "Aggressiveness and Sexualization of Video Game Characters Can Lead to Increased Levels of Aggressive Behaviour, Engagement in Sexual Harassment, and Tolerance of Sexual Harassment",
    author: "Kathleen Prior",
    abstract:
      "The robust finding that playing aggressive video games has the potential to cause short-term aggression is concerning as any aggression can lead to serious social conflict. In addition to the finding that playing video games with sexualized characters can lead to increased levels of sexual harassment and tolerance of sexual harassment in males (Burnay et al., 2019), video games with sexualized female characters  have also been shown to have a negative effect on self-efficacy in females (Behm-Morawitz, & Mastro, 2009). Advocacy should be made for less sexualized characters in video games. Since the popularity of video games grows each year (American Software Association, 2018), more experimental longitudinal studies should be conducted to further examine the long-term effects of playing video games with both aggressive and sexualized video game characters to better understand the extent of continuing aggression",
    keywords: "harm reduction, cannabis, Daybreak Child, Family Centre",
    first_page_number: 1,
    last_page_number: 10,
    additional_number: "",
    paper_link: "https://www.researchgate.net/project/Exchange-2020",
    published_on: "2020-03-04T05:00:00.000Z",
    file_name: "",
    affiliation: "Memorial University of Newfoundland",
  },
  {
    id: 4,
    conference_id: 2,
    presentation_id: 1,
    title: "Fentanyl Testing Strips Can Save Lives",
    author: "Ashley Power ",
    abstract:
      "Advocacy work and education are important to ensure that service users know how to use FTS properly. Depending on the substance, the method of testing may differ slightly. For example, if testing amphetamines the residue should be diluted in a cup of water to avoid a false positive. Instructions need to be geared toward the substance of choice which typically has regional variations. FTS increase individuals’ ability to make informed decisions.",
    keywords: "community partners, mental wellness, candlelighters ",
    first_page_number: 1,
    last_page_number: 10,
    additional_number: "",
    paper_link: "https://www.researchgate.net/project/Exchange-2020",
    published_on: "2020-03-04T05:00:00.000Z",
    file_name: "",
    affiliation: "Memorial University of Newfoundland",
  },
  {
    id: 5,
    conference_id: 2,
    presentation_id: 1,
    title:
      "Indigenous child welfare policy and practice: The efficacy and challenges of devolution and custom adoption",
    author: "AmyAnne Smith",
    abstract:
      "Devolution in Manitoba looked like a promising approach on paper and this provincial shift in service provision is similar to the proposed federal initiative as it stands today. Custom adoption is a promising practice model. This critical review highlights how practice models of Indigenous child welfare  services have strengths and weaknesses, and provide lessons learned that can be applied to the new federal initiatives (e.g., Bill C-92).",
    keywords: "Neurodisabilities, knowledge translation, social work",
    first_page_number: -1,
    last_page_number: 10,
    additional_number: "",
    paper_link: "https://www.researchgate.net/project/Exchange-2020",
    published_on: "2020-03-04T05:00:00.000Z",
    file_name: "",
    affiliation: "Memorial University of Newfoundland",
  },
  {
    id: 6,
    conference_id: 2,
    presentation_id: 1,
    title: "Social-Emotional Learning in Schools",
    author: "Caitlin Dillon",
    abstract:
      "The support from school social workers can aid professionals working within the educational system to help manage the health and development of its students – especially the social-emotional wellbeing of students. School social workers are mental health professionals who have been identified as the link between the home, school, and community and can provide direct and indirect services to students, families, and school personnel to support students’ academic and social-emotional success.",
    keywords: "Neurodisabilities, knowledge translation, social work",
    first_page_number: -1,
    last_page_number: 10,
    additional_number: "",
    paper_link: "https://www.researchgate.net/project/Exchange-2020",
    published_on: "2020-03-04T05:00:00.000Z",
    file_name: "",
    affiliation: "Memorial University of Newfoundland",
  },
];
