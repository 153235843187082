import Axios from "axios";
// import errorHandler from "./errorHandler";
// import authHeader from "./authHeader";

export const getEvents = async () => {
  return await Axios.get("/api/events/get-events");
};

export const deleteEvent = async (event_id) => {
  return await Axios.delete(`/api/events/delete-event/${event_id}`);
  // .then(() => {
  //   alert("Successfully deleted the event.");
  //   console.log(
  //     "ID - " + parseInt(event_id) + " has sent for deleting the event."
  //   );
  // })
  // .catch((err) => errorHandler(err));
};

const eventService = { getEvents, deleteEvent };
export default eventService;
