import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import DashboardLayout from "../layout/DashboardLayout";
import CardContent from "../common/CardContent";
import errorHandler from "../../services/errorHandler";
import { getConferences } from "../../services/conferenceService";
import FileUploader from "./FileUploader";
import ShowUploadedFile from "./ShowUploadedFile";
import Message from "./Message";

const ImageUpload = () => {
  // File upload states
  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState("Choose File");
  const [message, setMessage] = useState("");
  const [uploadedFile, setUploadedFile] = useState({});
  const [uploadPercentage, setUploadPercentage] = useState(0);
  // -----------------
  // const [loading, setLoading] = useState(false);
  const [conferences, setConferences] = useState([]);
  const [conferencesId, setConferencesID] = useState(null);
  const [caption, setCaption] = useState("");
  const [description, setDescription] = useState("");
  const [capturedOn, setCapturedOn] = useState("");

  const handleFileInput = (e) => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name || "Choose File");
    if (e.target.files[0].size > 2048) {
      setMessage("File size cannot exceed more than 2MB");
    }
    console.log("file:", file, "filename:", filename);
  };

  useEffect(() => {
    // setLoading(true);         setLoading(false);
    getConferences()
      .then((res) => {
        setConferences(res.data);
        console.log("Conference:", res.data);
      })
      .catch((err) => {
        errorHandler(err);
      });
  }, []);
  console.log("Input", conferencesId, caption, description, capturedOn);

  const submitForm = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("conference_id", conferencesId);
    formData.append("caption", caption);
    formData.append("description", description);
    formData.append("captured_on", capturedOn);

    formData.append("file", file);

    console.log("formData", formData);

    try {
      const res = await axios.post("/api/upload/image-upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          setUploadPercentage(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );
          // Clear percentage after 5 sec
          setTimeout(() => setUploadPercentage(0), 1000 * 5);
        },
      });

      const { file_name, file_path } = res.data;
      setUploadedFile({ file_name, file_path });
      setMessage("File Uploaded");
    } catch (err) {
      if (err.response.status === 500) {
        setMessage("There was a problem with the server");
      } else {
        setMessage(err.response.data.message);
      }
    }
  };

  return (
    <>
      <DashboardLayout page_name='Image Upload'>
        <CardContent title=' Image Upload'>
          <form className='mb-3'>
            <div className='form-row'>
              <div className='form-group col-md-6'>
                <label htmlFor='published_on'>Conference:</label>

                <select
                  className='form-control'
                  name='conference_id'
                  onChange={(e) => {
                    setConferencesID(e.target.value);
                  }}
                >
                  <option defaultValue> Select a conference</option>
                  {conferences.map((data, idx) => (
                    <option key={idx} value={data.id}>
                      {data.name}
                    </option>
                  ))}
                </select>
              </div>
              {/*******  Capture on *******/}
              <div className='form-group col-md-6'>
                <label htmlFor='captured_on'>Captured on:</label>
                <input
                  type='date'
                  className='form-control'
                  name='captured_on'
                  value={capturedOn}
                  onChange={(e) => setCapturedOn(e.target.value)}
                  placeholder='Captured on'
                />
              </div>
              {/*******  Paper Title *******/}
              <div className='form-group col-md-12'>
                <input
                  type='text'
                  className='form-control'
                  name='caption'
                  value={caption}
                  placeholder='Image caption'
                  onChange={(e) => setCaption(e.target.value)}
                  required
                />
              </div>

              {/*******  Paper Title *******/}
              <div className='form-group col-md-12'>
                <textarea
                  // type='text'
                  className='form-control'
                  name='description'
                  value={description}
                  placeholder='Description'
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
              </div>
            </div>

            <FileUploader
              handleFileInput={handleFileInput}
              uploadPercentage={uploadPercentage}
              message={message}
              filename={filename}
            />
          </form>
          {/*******  Submit Button  *******/}
          <button
            type='submit'
            className='btn btn-info mt-0'
            onClick={submitForm}
          >
            Submit
          </button>
          <Link to='/dashboard' className='btn btn-danger ml-3 mb-0'>
            Cancel
          </Link>
          {/* ---------------------- */}
          {uploadedFile ? (
            <div>
              {message ? <Message message={message} /> : null}
              <ShowUploadedFile
                file_name={uploadedFile.file_name}
                file_path={uploadedFile.file_path}
              />
            </div>
          ) : null}
        </CardContent>
      </DashboardLayout>
    </>
  );
};

export default ImageUpload;
