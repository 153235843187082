import Axios from "axios";
// import errorHandler from "./errorHandler";
// import authHeader from "./authHeader";

export const getConferences = async () => {
  return await Axios.get("/api/conferences/get-conferences");
};

export const deleteConference = async (conf_id) => {
  return await Axios.delete(`/api/conferences/delete-conference/${conf_id}`);
};

export const getPresentationTypes = async () => {
  return await Axios.get("/api/conferences/get-presentation-types");
};

const conferenceService = {
  getPresentationTypes,
  getConferences,
  deleteConference,
};
export default conferenceService;
