import React from "react";

export default function ContactInfo(props) {
  return (
    <>
      <section>
        <div className='card text-center shadow mb-2'>
          {/* /************************ */}
          {props.showBiggerTitle && (
            <div className='card-header conference-bg-color mb-0'>
              <div className='card-title new-card-title'>{props.title}</div>
            </div>
          )}

          {/* /************************ */}
          <div className='card-body text-dark'>
            {/*---- Start the Text-Style title -----*/}
            {!props.showBiggerTitle && (
              <div>
                <h5 className='card-title'> {props.title}</h5>
                <div className='border-top border-primary w-75 mx-auto my-3'></div>
              </div>
            )}
            {/*---- End the Text-Style title -----*/}

            <div className='text-justify card-text px-4'>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <strong>
                        Mailing Address:
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                      </strong>
                    </td>
                    <td>&nbsp; </td>
                    <td>
                      Dr. Stephen Ellenbogen
                      <br />
                      School of Social Work
                      <br />
                      Memorial University of Newfoundland
                      <br />
                      St. John's, NL, Canada
                      <br />
                      A1C 5S7
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>
                        <br />
                        Phone:
                        <br />
                      </strong>
                    </td>
                    <td>&nbsp;</td>
                    <td>
                      <br />
                      (709) 864-2559
                      <br />
                      {/* (709) 888-5678 */}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        Email: <br />
                      </strong>
                    </td>
                    <td>&nbsp;</td>
                    <td>
                      <a href='mailto:sellenbogen@mun.ca'>sellenbogen@mun.ca</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* <p className='text-justify px-4'>
              We are physically located in the Henrietta Harvey Building on
              Elizabeth Avenue in St. John's, between Westerland Road and
              Allendale Road, immediately east of the Arts and Administration
              Building.
            </p> */}
          </div>
          {/*---- End the body of Website Overview  -----*/}
        </div>
        {/*---- End Website Overview -----*/}
      </section>
    </>
  );
}
