import React, { useState } from "react";
import Message from "./Message";
import Progress from "./Progress";
import axios from "axios";

const FileUploader = () => {
  const [file, setFile] = useState("");
  const [filename, setFilename] = useState("Choose File");
  const [uploadedFile, setUploadedFile] = useState({});
  const [message, setMessage] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);

  // const [conference, setConference] = useState("");

  // const handleChange = (e) => {
  //   setConference({ ...conference, [e.target.name]: e.target.value });
  // };
  // const handleChange = (e) => {
  //   setConference(e.target.value);
  // };

  const handleFileInput = (e) => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
    console.log("file:", file);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    // formData.append("conference", conference);
    formData.append("file", file);
    // formData.append("file", file, "fileInfo", conference);

    // console.log("formData:", formData);
    try {
      const res = await axios.post("/api/upload/image-upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          // "Content-Type": "application/x-www-form-urlencoded",
        },
        onUploadProgress: (progressEvent) => {
          setUploadPercentage(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );

          // Clear percentage
          setTimeout(() => setUploadPercentage(0), 1000 * 20);
        },
      });

      const { file_name, file_path } = res.data;
      setUploadedFile({ file_name, file_path });
      setMessage("File Uploaded");
    } catch (err) {
      if (err.response.status === 500) {
        setMessage("There was a problem with the server");
      } else {
        setMessage(err.response.data.message);
      }
    }
  };

  return (
    <>
      <div>
        {message ? <Message msg={message} /> : null}
        <form onSubmit={onSubmit}>
          <div className='custom-file mb-4'>
            <input
              type='file'
              className='custom-file-input'
              id='customFile'
              onChange={handleFileInput}
            />
            <label className='custom-file-label' htmlFor='custom_file'>
              {filename}
            </label>
          </div>

          <Progress percentage={uploadPercentage} />

          <input
            type='submit'
            value='Upload'
            className='btn btn-primary btn-block mt-4'
          />
        </form>
        {uploadedFile ? (
          <div className='row mt-5'>
            <div className='col-md-6 m-auto'>
              <h3 className='text-center'>{uploadedFile.file_name}</h3>
              <img
                style={{ width: "100%" }}
                src={uploadedFile.file_path}
                alt=''
              />
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default FileUploader;
